<template>
  <KalmModal modalTitle="Photos & pièces-jointes" @close="close">
    <template v-slot:body>
      <FilesForTaskForm :taskId="taskId" />
    </template>
  </KalmModal>
</template>

<script>
import KalmModal from "@/views/_core/components/KalmModal.vue";
import FilesForTaskForm from "./FilesForTaskForm.vue";

export default {
  name: "FilesForTaskModal",
  props: {
    taskId: {
      type: String,
      required: true,
    },
  },
  components: {
    FilesForTaskForm,
    KalmModal,
  },
};
</script>
