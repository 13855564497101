<template>
  <div
    id="modal"
    aria-hidden="true"
    aria-labelledby="modalLabel"
    class="modal"
    style="display: block"
    tabindex="-1"
    @click.self="this.clickOutside()"
  >
    <div class="modal-dialog modal-dialog-centered mw-600px">
      <div class="modal-content mx-5">
        <div class="container px-5 py-5">
          <div class="modal-header p-0 d-flex justify-content-end border-0 sticky-top">
            <!--begin::Close-->
            <div class="btn btn-icon btn-sm btn-light-primary" @click="close()">
              <i class="bi bi-x-lg"></i>
            </div>
            <!--end::Close-->
          </div>
          <div class="modal-body">
            <!--begin::Content-->
            <div class="d-flex flex-row-fluid justify-content-center">
              <!--begin::Form-->
              <div class="pb-5 w-100">
                <h3 class="fw-bolder text-dark display-6">{{ modalTitle }}</h3>
                <slot name="body"></slot>
              </div>
            </div>
          </div>
          <div class="container sticky-bottom bg-white">
            <div class="modal-footer border-0">
              <slot name="footer"></slot>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "KalmModal",
  props: {
    modalTitle: String,
  },
  emits: ["close", "clickOutside"],
  methods: {
    close() {
      this.$emit("close");
    },
    clickOutside() {
      this.$emit("clickOutside");
    },
  },
};
</script>
