<template>
  <!--begin::Content-->
  <div :class="{ 'blur-content': this.isModalActive }">
    <div class="print-hidden">
      <ProjectBreadcrumbComponent
        :options="[
          { name: 'Suivi', id: 'project.tender.manage' },
          { name: 'Comparatif', id: 'project.tender.table' },
          {
            name: 'Scénarios',
            id: 'project.tender.scenarios',
            disabled: !this.currentUser?.groups?.includes('plan_premium'),
          },
        ]"
        :project_id="$route.params.id"
        current_route="project.tender.table"
        title="Consultation"
      ></ProjectBreadcrumbComponent>
    </div>
    <!--begin::Row-->
    <div class="row print-hidden">
      <div class="col-12">
        <ProgressCard
          :card_progress_percent="
            ((card_count_proposals_sent + card_count_proposals_received) / this.card_count_proposals_created) * 50
          "
          :card_text_line_1="
            '' +
            this.card_count_proposals_sent +
            (this.card_count_proposals_sent !== 1 ? ' descriptifs envoyés' : ' descriptif envoyé')
          "
          :card_text_line_2="
            '' +
            this.card_count_proposals_received +
            (this.card_count_proposals_received !== 1 ? ' chiffrages reçus' : ' chiffrage reçu')
          "
          card_title="DCE • Consultation"
          card_type="kalm"
        />
      </div>
    </div>
    <PrintHeader
      :pageTitle="this.printOptions.title"
      :sectionText="this.printOptions.text"
      :sectionTitle="this.printOptions.subtitle"
    ></PrintHeader>
    <!--end::Row-->
    <h2 class="text-dark fw-bold fs-1 mb-5 print-hidden">Devis et estimatifs</h2>
    <!--begin::Row-->
    <div class="row col-12 g-0">
      <!--begin::Card-->
      <div class="card container-fluid px-2">
        <div class="card-toolbar py-2 w-100 text-end print-hidden">
          <button
            v-tooltip:bottom="'Afficher/Masquer le bloc de texte pour l\'export'"
            class="btn btn-icon me-1 btn-active-light btn-color-muted fw-bolder"
            type="button"
            @click="this.showPrintCustomText = !this.showPrintCustomText"
          >
            <span class="bi align-baseline bi-fonts"></span>
          </button>
          <button
            v-tooltip:bottom="'Tout afficher / tout masquer'"
            class="btn btn-icon me-1 btn-active-light btn-color-muted fw-bolder"
            type="button"
            @click="this.toggleOrUntoggleAllLots()"
          >
            <span v-if="this.toggledLots.length === 0" class="bi align-baseline bi-arrows-expand"></span
            ><span v-else class="bi align-baseline bi-arrows-collapse"></span>
          </button>
          <button
            class="btn me-1 btn-active-light btn-color-muted fw-bolder"
            type="button"
            @click="this.$router.push({ name: 'project.tender.table_lots' })"
          >
            <span class="bi align-baseline bi-filter me-2"></span>Filtrer sur un lot
          </button>
          <button class="btn me-1 btn-active-light btn-color-muted fw-bolder" type="button" @click="this.printTender()">
            <span class="bi align-baseline bi-download me-2"></span>Consultation
          </button>
          <button
            class="btn me-1 btn-active-light btn-color-muted fw-bolder"
            type="button"
            @click="this.printEstimation()"
          >
            <span class="bi align-baseline bi-download me-2"></span>Estimatif
          </button>
          <button
            class="btn me-1 btn-active-light btn-color-muted fw-bolder"
            type="button"
            @click="this.$router.push({ name: 'project.tender.manage' })"
          >
            <span class="bi align-baseline bi-plus"></span> Ajouter un devis
          </button>
        </div>
        <div v-if="this.showPrintCustomText" class="w-100 print-hidden">
          <textarea
            v-model="this.printCustomText"
            class="form-control"
            placeholder="Texte non sauvegardé qui sera affiché à l'impression"
          ></textarea>
        </div>
        <p class="d-print-block d-none text-center" style="white-space: pre-wrap">{{ this.printCustomText }}</p>
        <div class="card-body pb-5 d-flex">
          <!--begin::Table-->
          <table class="table h-1px mb-0" style="border-collapse: collapse !important">
            <!--begin::head-->
            <thead>
              <tr class="fw-bolder fs-6 text-gray-800 h-100 p-0">
                <th class=""></th>
                <th class="w-auto"></th>
                <th class="w-20px"></th>
                <th
                  v-for="proposal in this.proposals"
                  :key="proposal.id"
                  :class="{
                    'print-hidden': proposal.hide || (proposal?.contact && this.printOptions.hideProposalsFromPrint),
                  }"
                  class="h-100 w-150px px-2 py-0 web-sticky"
                  scope="col"
                >
                  <div
                    :class="proposal.hide ? 'bg-light text-gray-800 print-hidden' : 'bg-primary text-white'"
                    class="rounded-top h-100 w-100 p-4 border border-primary border-bottom-0 text-center"
                  >
                    {{ proposal.name || proposal.contact.company || proposal.contact.name }}
                  </div>
                </th>
              </tr>
              <tr class="fw-bolder fs-6 text-gray-800 h-100 p-0 print-hidden">
                <td class=""></td>
                <td class=""></td>
                <td class=""></td>
                <!--suppress HtmlDeprecatedAttribute -->
                <td
                  v-for="proposal in this.proposals"
                  :key="proposal.id"
                  :class="{
                    'print-hidden': proposal.hide || (proposal?.contact && this.printOptions.hideProposalsFromPrint),
                  }"
                  class="h-100 w-150px px-2 py-0"
                  scope="col"
                >
                  <div
                    :class="proposal.hide ? 'bg-light text-gray-800 print-hidden' : 'bg-primary text-white'"
                    class="h-100 w-100 border border-primary border-bottom-0 border-top-0 text-center"
                  >
                    <span v-if="proposal.hide" class="pt-2" @click="this.hideProposal(proposal.id, false)"
                      ><span class="bi bi-eye-slash-fill cursor-pointer text-gray-800 w-100 py-3 print-hidden"></span
                    ></span>
                    <span v-else class="pt-2" @click="this.hideProposal(proposal.id, true)"
                      ><span class="bi bi-eye-fill cursor-pointer text-white w-100 py-3 print-hidden"></span
                    ></span>
                  </div>
                </td>
              </tr>
            </thead>
            <!--end::head-->

            <!--begin::body-->
            <tbody class="h-100" style="">
              <!--begin::emptyRow-->
              <tr class="h-100 bg-white" style="">
                <td class="h-100"></td>
                <td class="h-100"></td>
                <td class=""></td>
                <td
                  v-for="proposal in this.proposals"
                  :key="proposal.id"
                  :class="{
                    'print-hidden': proposal.hide || (proposal?.contact && this.printOptions.hideProposalsFromPrint),
                  }"
                  class="d-table-cell h-100 px-2 py-0"
                  style=""
                >
                  <div class="h-100 w-100 border-start border-end border-1 border-primary text-center"></div>
                </td>
              </tr>
              <!--end::emptyRow-->

              <!--begin::tableContent-->
              <template v-for="lot in this.lots" :key="lot.id">
                <!--begin:lotHeader-->
                <tr
                  v-if="!this.getParentLot(lot) || this.lotToggled(this.getParentLot(lot)?.id)"
                  :class="showLots[lot.id] ? '' : 'print-hidden'"
                  class="h-100 lot-header"
                >
                  <td class="w-print-200 py-2 h-100">
                    <div :class="this.getTitleStyleForLot(lot)" class="d-flex w-print-200">
                      <h3
                        v-if="this.lotHasChildrenOrOuvrages(lot)"
                        :class="{ 'text-muted': !this.showLots[lot.id] }"
                        class="cursor-pointer align-self-center w-print-200"
                        @click="this.toggleLot(lot.id)"
                      >
                        <span class="print-hidden">
                          <i
                            :class="this.lotToggled(lot.id) ? 'bi-arrow-down text-primary' : 'bi-arrow-right'"
                            class="bi me-2"
                          ></i>
                        </span>
                        {{ lot.fullName }}
                      </h3>
                      <h3
                        v-else
                        v-tooltip:bottom="'Ce lot n\'a aucun ouvrage ou sous-lot'"
                        :class="this.showLots[lot.id] ? '' : 'text-muted'"
                        class="align-self-center w-print-200"
                      >
                        <span class="print-hidden">
                          <i class="bi me-2 bi-arrow-right"></i>
                        </span>
                        {{ lot.fullName }}
                      </h3>
                    </div>
                  </td>
                  <td class="py-1 h-100"></td>
                  <td class="align-middle">
                    <i
                      :class="this.showLots[lot.id] ? 'bi-eye-fill text-grey-800' : 'bi-eye-slash-fill text-grey-600'"
                      class="bi cursor-pointer print-hidden"
                      @click="this.toggleHideLot(lot.id)"
                    ></i>
                  </td>
                  <td
                    v-for="proposal in this.proposals"
                    :key="proposal.id"
                    :class="{
                      'print-hidden': proposal.hide || (proposal?.contact && this.printOptions.hideProposalsFromPrint),
                    }"
                    class="d-table-cell px-2 py-0 h-100"
                  >
                    <div
                      :class="this.showLots[lot.id] ? 'text-dark' : 'text-muted'"
                      class="h-100 w-100 d-flex justify-content-center border-start border-end border-1 border-primary text-center text-dark fw-bold"
                    >
                      <div class="align-self-center">{{ this.proposalSubtotalFormatted(proposal.id, lot.id) }}</div>
                    </div>
                  </td>
                </tr>
                <!--end:lotHeader-->
                <!--begin:lotContent-->
                <template
                  v-if="
                    this.lotToggled(lot.id) && (!this.getParentLot(lot) || this.lotToggled(this.getParentLot(lot)?.id))
                  "
                >
                  <template v-for="(chiffrage, index) in getChiffragesForLot(lot)" :key="chiffrage.id">
                    <tr v-if="chiffrage?.groupTitle !== getChiffragesForLot(lot)[index - 1]?.groupTitle">
                      <td class="text-uppercase text-primary fw-light">{{ chiffrage?.groupTitle }}</td>
                      {{}}
                      <td class="py-0"></td>
                      <td class=""></td>
                      <td
                        v-for="proposal in this.proposals"
                        :key="proposal.id"
                        :class="{
                          'print-hidden':
                            proposal.hide || (proposal?.contact && this.printOptions.hideProposalsFromPrint),
                        }"
                        class="d-table-cell px-2 py-0 h-100"
                      >
                        <div
                          class="h-100 w-100 border-start border-end border-1 border-primary text-center"
                          style="height: 100%"
                        ></div>
                      </td>
                    </tr>
                    <tr :class="showLots[lot.id] ? '' : 'print-hidden text-muted'" class="h-100 lot-content">
                      <td class="py-0">
                        <p>
                          <span
                            class="me-2 bi bi-info-circle text-muted"
                            @mouseenter="showOuvrageInfoTooltip(chiffrage.ouvrageId, true)"
                            @mouseleave="showOuvrageInfoTooltip(chiffrage.ouvrageId, false)"
                          >
                            <span
                              v-if="chiffrage.ouvrageId === this.tooltip"
                              class="ms-2 bg-white text-gray-800 p-5 border border-primary rounded"
                              style="position: absolute; z-index: 1000"
                            >
                              <span v-if="chiffrage.numero" class="font-monospace text-muted me-2">{{
                                chiffrage?.numero
                              }}</span>
                              <span class="fw-bolder text-black">{{ chiffrage.title }}</span>
                              <span v-if="chiffrage.description" style="white-space: pre-wrap"
                                ><br />Description : {{ chiffrage.description }}</span
                              >
                              <span v-if="chiffrage.qt"><br />Quantité prévue : {{ chiffrage.qt }}</span>
                              <span
                                v-if="
                                  this.currentProject.featureMultiLocation && chiffrage.locations.map((l) => l.name)
                                "
                                ><br />Emplacement :
                                {{ chiffrage.locations.map((l) => l.name).join(", ") || "Sans emplacement" }}</span
                              >
                              <span v-else-if="chiffrage.location?.name"
                                ><br />Emplacement : {{ chiffrage.location?.name }}</span
                              >
                            </span>
                          </span>
                          <span v-if="chiffrage?.numero" class="me-2 font-monospace text-muted">{{
                            chiffrage.numero
                          }}</span>
                          {{ chiffrage.title }}
                        </p>
                      </td>
                      <td class="py-0"></td>
                      <td class=""></td>
                      <td
                        v-for="proposal in this.proposals"
                        :key="proposal.id"
                        :class="{
                          'print-hidden':
                            proposal.hide || (proposal?.contact && this.printOptions.hideProposalsFromPrint),
                        }"
                        class="d-table-cell px-2 py-0 h-100"
                      >
                        <div
                          class="h-100 w-100 border-start border-end border-1 border-primary text-center"
                          style="height: 100%"
                        >
                          <span>{{ this.getProposalChiffrageForOuvrage(proposal, lot.id, chiffrage.ouvrageId) }}</span>
                        </div>
                      </td>
                    </tr>
                  </template>
                </template>
                <!--end:lotContent-->
              </template>
              <!--end::tableContent-->

              <!--begin:total-->
              <tr class="h-100 lot-header">
                <td class="text-end" colspan="3">Total HT</td>
                <td
                  v-for="proposal in this.proposals"
                  :key="proposal.id"
                  :class="{
                    'print-hidden': proposal.hide || (proposal?.contact && this.printOptions.hideProposalsFromPrint),
                  }"
                  class="d-table-cell align-middle px-2 py-0 h-100"
                >
                  <div
                    class="h-100 w-100 d-flex justify-content-center border-start border-end border-top border-top-dotted border-1 border-primary text-center text-primary fw-bold"
                    style="border-right: 1px solid #195d5c !important; border-left: 1px solid #195d5c !important"
                  >
                    <div class="align-self-center">
                      {{ this.proposalTotal(proposal.id).replace(/\B(?=(\d{3})+(?!\d))/g, " ") }} €
                    </div>
                  </div>
                </td>
              </tr>
              <!--end:total-->

              <!--begin:total-->
              <tr class="h-100 lot-header">
                <td class="text-end" colspan="3">TVA</td>
                <td
                  v-for="proposal in this.proposals"
                  :key="proposal.id"
                  :class="{
                    'print-hidden': proposal.hide || (proposal?.contact && this.printOptions.hideProposalsFromPrint),
                  }"
                  class="d-table-cell align-text-bottom px-2 py-0 h-100"
                >
                  <div
                    class="cell h-100 w-100 d-flex justify-content-center border-start border-end border-top border-top-dotted border-1 border-primary text-center text-primary fw-bold"
                    style="border-right: 1px solid #195d5c !important; border-left: 1px solid #195d5c !important"
                  >
                    <div class="align-self-center">
                      {{ this.proposalVatTotal(proposal.id).replace(/\B(?=(\d{3})+(?!\d))/g, " ") }} €
                    </div>
                  </div>
                </td>
              </tr>
              <!--end:total-->

              <!--begin:total-->
              <tr class="h-100 lot-header">
                <td class="text-end" colspan="3">Total TTC</td>
                <td
                  v-for="proposal in this.proposals"
                  :key="proposal.id"
                  :class="{
                    'print-hidden': proposal.hide || (proposal?.contact && this.printOptions.hideProposalsFromPrint),
                  }"
                  class="d-table-cell align-middle px-2 py-0 h-100"
                >
                  <div
                    class="h-100 w-100 d-flex justify-content-center border-start border-end border-top border-top-dotted border-1 border-primary text-center text-primary fw-bold"
                    style="border-right: 1px solid #195d5c !important; border-left: 1px solid #195d5c !important"
                  >
                    <div class="align-self-center">
                      {{
                        (parseFloat(this.proposalTotal(proposal.id)) + parseFloat(this.proposalVatTotal(proposal.id)))
                          .toFixed(2)
                          .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
                      }}
                      €
                    </div>
                  </div>
                </td>
              </tr>
              <!--end:total-->

              <!--begin:roundedBottom-->
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td
                  v-for="proposal in this.proposals"
                  :key="proposal.id"
                  :class="{
                    'print-hidden': proposal.hide || (proposal?.contact && this.printOptions.hideProposalsFromPrint),
                  }"
                  class="px-2 py-0 h-100"
                >
                  <div
                    :class="proposal.hide ? 'bg-light text-gray-800' : 'bg-primary text-white'"
                    class="edit-proposal-button rounded-bottom h-100 w-100 border border-primary border-top-0 text-center p-2"
                    @click="modalEditProposalShow(proposal.id)"
                  >
                    <i
                      :class="proposal.hide ? 'text-gray-800' : 'text-white'"
                      class="bi print-hidden bi-pencil-fill"
                    ></i>
                  </div>
                </td>
              </tr>
              <!--end:roundedBottom-->
            </tbody>
            <!--end::body-->
          </table>
          <!--end::Table-->
        </div>
      </div>
      <!--end::Card-->
    </div>
    <!--end::Row-->
  </div>
  <!--end::Content-->

  <!--begin::Modal Edit Tender-->
  <div :style="this.modals.editProposal.show ? 'display: block;' : 'display: none;'" class="modal" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered mw-800px">
      <div class="modal-content mx-5">
        <div class="container px-10 py-10">
          <div class="modal-header p-0 d-flex justify-content-end border-0">
            <!--begin::Close-->
            <div class="btn btn-icon btn-sm btn-light-primary" @click="modalEditProposalClose()">
              <i class="bi bi-x-lg"></i>
            </div>
            <!--end::Close-->
          </div>

          <div class="modal-body p-0">
            <!--begin::Content0-->
            <div class="d-flex flex-row-fluid justify-content-center">
              <!--begin::Form-->
              <div class="pb-5 w-100 w-sm-700px">
                <!--begin::Step-->
                <div class="pb-5 completed">
                  <div class="w-100">
                    <!--begin::Heading-->
                    <div class="pb-5">
                      <h3 class="fw-bolder text-dark display-6 mb-0">
                        <span v-if="this.getProposalById(this.modals.editProposal.id)?.contact"
                          >Modifier le chiffrage</span
                        >
                        <span v-else>Modifier l'estimatif</span>
                      </h3>
                      <p
                        v-if="this.getProposalById(this.modals.editProposal.id)?.contact"
                        class="d-inline-block text-gray-500 fw-bold"
                      >
                        <i class="bi bi-building me-2"></i
                        >{{
                          this.getProposalById(this.modals.editProposal.id)?.contact?.company ||
                          this.getProposalById(this.modals.editProposal.id)?.contact?.name
                        }}
                      </p>

                      <div class="ps-2 my-2">
                        <div class="row mt-2">
                          <div class="form-check form-switch">
                            <input
                              id="1"
                              v-model="this.modals.editProposal.showDetails"
                              class="form-check-input"
                              role="switch"
                              type="checkbox"
                            />
                            <label class="form-check-label" for="1">Détailler tous les ouvrages</label>
                          </div>
                        </div>
                      </div>

                      <div class="mt-7">
                        <label class="form-label" for="consultationFile"
                          >Nouveau : importer depuis une DPGF en format Excel<span
                            v-tooltip:bottom="
                              'Fonctionne uniquement avec les DPGFs générées par Kalm dont le format reste inchangé'
                            "
                            class="bi bi-info-circle text-muted ms-2"
                          ></span
                        ></label>
                        <input
                          ref="consultationFile"
                          accept=".xlsx, .xls"
                          class="form-control"
                          type="file"
                          @change="this.tenderImportFromExcelLoad"
                        />
                        <div v-if="this.tenderImportFromExcel.ready" class="pt-3">
                          <p v-if="this.tenderImportFromExcel.error">
                            Il y a eu une erreur lors de l'import, le format est-il bien celui fourni par Kalm ?
                          </p>
                          <p v-else>
                            Êtes-vous sûrs ? Cela importera : {{ this.tenderImportFromExcel.countQuantity }} quantités,
                            {{ this.tenderImportFromExcel.countUnit }} unités,
                            {{ this.tenderImportFromExcel.countValue }} totaux HT et
                            {{ this.tenderImportFromExcel.countUnitPrice }} prix unitaires.
                          </p>
                          <div>
                            <button
                              :disabled="!this.tenderImportFromExcel.ready || this.tenderImportFromExcel.error"
                              class="btn btn-primary"
                              @click="this.tenderImportFromExcelValidate"
                            >
                              Confirmer l'import
                            </button>
                            <button class="btn btn-secondary ms-2" @click="this.tenderImportFromExcelCancel(false)">
                              Annuler
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="">
                      <template v-for="lot in this.getLotsForProposalById(this.modals.editProposal.id)" :key="lot.id">
                        <div class="mt-7">
                          <div class="d-flex justify-content-between align-middle">
                            <h3 class="mt-3 mb-3 me-auto">{{ lot.fullName }}</h3>
                            <div class="input-group mt-0 w-150px h-30px">
                              <input
                                v-if="this.modals.editProposal.id"
                                v-model="this.modals.editProposal.lotGlobalValues[lot.id].sum"
                                :placeholder="this.proposalSubtotalForChiffrageUpdateModal(lot.id)"
                                class="form-control py-0 h-30px"
                                type="number"
                                @click="
                                  this.modals.editProposal.lotGlobalValues[lot.id].sum == 0
                                    ? $event.target.select()
                                    : ''
                                "
                                @mousewheel="this.handleMouseWheelOnNumberInput"
                              />
                              <span class="input-group-text h-30px">€ HT</span>
                            </div>
                            <div
                              v-if="!!this.modals.editProposal.lotGlobalValues[lot.id]?.sum"
                              class="input-group ms-2 mt-0 w-150px h-30px"
                            >
                              <input
                                v-model="this.modals.editProposal.lotGlobalValues[lot.id].vat"
                                :placeholder="this.getProposalById(this.modals.editProposal.id).vatDefaultValue"
                                class="form-control py-0 h-30px"
                                type="number"
                                @click="$event.target.select()"
                                @mousewheel="this.handleMouseWheelOnNumberInput"
                              />
                              <span class="input-group-text h-30px">% TVA</span>
                            </div>
                          </div>
                          <p
                            v-if="
                              this.getProposalById(this.modals.editProposal.id).chiffrages?.filter(
                                (c) => c?.lot?.id === lot?.id
                              ).length === 0
                            "
                            class="text-muted"
                          >
                            Aucun ouvrage pour ce lot, ajoutez un ouvrage dans le descriptif puis cliquez sur "Mettre à
                            jour les ouvrages de la consultation" dans Consultation > Suivi.
                          </p>
                          <div
                            v-for="chiffrage in this.getProposalById(this.modals.editProposal.id)
                              ?.chiffrages?.filter((l) => l.lot.id === lot.id)
                              .sort(function (a, b) {
                                if (a.order < b.order) {
                                  return -1;
                                }
                                if (a.order > b.order) {
                                  return 1;
                                }
                                return 0;
                              })"
                            :key="chiffrage.id"
                            class="row"
                          >
                            <div class="col-12 col-form-label">
                              <span
                                v-if="!this.modals.editProposal.showDetails"
                                class="me-2 bi bi-info-circle text-muted"
                                @mouseenter="showOuvrageInfoTooltip(chiffrage.ouvrageId, true)"
                                @mouseleave="showOuvrageInfoTooltip(chiffrage.ouvrageId, false)"
                              >
                                <span
                                  v-if="chiffrage.ouvrageId === this.tooltip"
                                  class="ms-2 bg-white text-gray-800 p-5 border border-primary rounded"
                                  style="position: absolute; z-index: 1000"
                                >
                                  <span v-if="chiffrage.numero" class="font-monospace text-muted me-2">{{
                                    chiffrage?.numero
                                  }}</span
                                  ><br />
                                  <span class="fw-bolder text-black">{{ chiffrage.title }}</span>
                                  <span v-if="chiffrage.description" style="white-space: pre-wrap"
                                    ><br />Description : {{ chiffrage.description }}</span
                                  >
                                  <span v-if="chiffrage.qt"><br />Quantité prévue : {{ chiffrage.qt }}</span>
                                  <span
                                    v-if="
                                      this.currentProject.featureMultiLocation && chiffrage.locations.map((l) => l.name)
                                    "
                                    ><br />Emplacement :
                                    {{ chiffrage.locations.map((l) => l.name).join(", ") || "Sans emplacement" }}</span
                                  >
                                  <span v-else-if="chiffrage.location?.name"
                                    ><br />Emplacement : {{ chiffrage.location?.name }}</span
                                  >
                                </span>
                              </span>
                              <span v-if="chiffrage.numero" class="font-monospace text-muted me-2">{{
                                chiffrage?.numero
                              }}</span>
                              <span
                                :class="
                                  !!this.modals.editProposal.lotGlobalValues[lot.id]?.sum ? 'text-muted' : 'text-black'
                                "
                                >{{ chiffrage.title }}</span
                              >
                              <span v-if="this.modals.editProposal.showDetails" class="text-gray-800">
                                <span v-if="chiffrage.description" style="white-space: pre-wrap"
                                  ><br />Description : {{ chiffrage.description }}</span
                                >
                                <span v-if="chiffrage.qt"><br />Quantité prévue : {{ chiffrage.qt }}</span>
                                <span
                                  v-if="
                                    this.currentProject.featureMultiLocation && chiffrage.locations.map((l) => l.name)
                                  "
                                  ><br />Emplacement :
                                  {{ chiffrage.locations.map((l) => l.name).join(", ") || "Sans emplacement" }}</span
                                >
                                <span v-else-if="chiffrage.location?.name"
                                  ><br />Emplacement : {{ chiffrage.location?.name }}</span
                                >
                              </span>
                            </div>
                            <div class="col-12 py-1 d-flex align-middle my-auto">
                              <div class="align-middle">
                                <label class="text-muted">Prix unit. €</label>
                                <input
                                  v-model="this.modals.editProposal.chiffrages[chiffrage.ouvrageId].unitPrice"
                                  :class="
                                    !!this.modals.editProposal.lotGlobalValues[lot.id]?.sum
                                      ? 'text-muted'
                                      : 'text-black'
                                  "
                                  :disabled="!!this.modals.editProposal.lotGlobalValues[lot.id]?.sum"
                                  class="form-control"
                                  type="number"
                                  @click="$event.target.select()"
                                  @input="this.updateChiffrageValue(chiffrage.ouvrageId)"
                                  @mousewheel="this.handleMouseWheelOnNumberInput"
                                />
                              </div>
                              <div class="ms-2 align-middle">
                                <label class="text-muted">Qt</label>
                                <div class="input-group">
                                  <input
                                    v-model="this.modals.editProposal.chiffrages[chiffrage.ouvrageId].quantity"
                                    :class="
                                      !!this.modals.editProposal.lotGlobalValues[lot.id]?.sum
                                        ? 'text-muted'
                                        : 'text-black'
                                    "
                                    :disabled="!!this.modals.editProposal.lotGlobalValues[lot.id]?.sum"
                                    class="form-control"
                                    type="number"
                                    @click="$event.target.select()"
                                    @input="this.updateChiffrageValue(chiffrage.ouvrageId)"
                                    @mousewheel="this.handleMouseWheelOnNumberInput"
                                  />
                                  <select
                                    v-model="this.modals.editProposal.chiffrages[chiffrage.ouvrageId].unit"
                                    :disabled="!!this.modals.editProposal.lotGlobalValues[lot.id]?.sum"
                                    class="form-control form-select"
                                  >
                                    <option selected>ens</option>
                                    <option>u</option>
                                    <option>m</option>
                                    <option>m2</option>
                                    <option>m3</option>
                                    <option>ml</option>
                                    <option>g</option>
                                    <option>kg</option>
                                    <option>t</option>
                                    <option>forfait</option>
                                    <option hidden>m²</option>
                                    <option hidden>m³</option>
                                    <option>jours</option>
                                    <option>mois</option>
                                    <option>h</option>
                                    <option></option>
                                  </select>
                                </div>
                              </div>
                              <div class="ms-2 align-middle">
                                <label class="text-muted">Total HT</label>
                                <input
                                  v-model="this.modals.editProposal.chiffrages[chiffrage.ouvrageId].value"
                                  :class="
                                    this.isChiffrageSumIncoherent(chiffrage.ouvrageId)
                                      ? 'text-warning'
                                      : !!this.modals.editProposal.lotGlobalValues[lot.id]?.sum
                                        ? 'text-muted'
                                        : 'text-black'
                                  "
                                  :disabled="!!this.modals.editProposal.lotGlobalValues[lot.id]?.sum"
                                  class="form-control"
                                  type="number"
                                  @click="$event.target.select()"
                                  @mousewheel="this.handleMouseWheelOnNumberInput"
                                />
                              </div>
                              <div class="ms-2 align-middle">
                                <label class="text-muted">% TVA</label>
                                <input
                                  v-model="this.modals.editProposal.chiffrages[chiffrage.ouvrageId].vatRate"
                                  :class="
                                    !!this.modals.editProposal.lotGlobalValues[lot.id]?.sum
                                      ? 'text-muted'
                                      : 'text-black'
                                  "
                                  :disabled="!!this.modals.editProposal.lotGlobalValues[lot.id]?.sum"
                                  :placeholder="this.modals.editProposal.vatDefaultValue"
                                  class="form-control"
                                  type="number"
                                  @click="$event.target.select()"
                                  @mousewheel="this.handleMouseWheelOnNumberInput"
                                />
                              </div>
                            </div>
                            <div v-if="this.isChiffrageSumIncoherent(chiffrage.ouvrageId)" class="text-warning">
                              <span class="bi bi-exclamation-triangle me-2"></span>Les quantités et le prix unitaire ne
                              correspondent pas au prix total.
                              <button
                                class="ms-2 btn p-2 btn-warning"
                                @click="this.updateChiffrageValue(chiffrage.ouvrageId)"
                              >
                                Recalculer
                              </button>
                            </div>
                          </div>
                        </div>
                      </template>
                    </div>
                  </div>
                </div>
                <!--end::Step-->

                <!--begin::Actions-->
                <div class="d-flex justify-content-end">
                  <div>
                    <button
                      class="me-2 btn btn-lg btn-light-primary btn-active-accent"
                      type="button"
                      @click="modalEditProposalClose()"
                    >
                      Annuler
                    </button>
                    <button
                      class="btn btn-lg btn-primary btn-active-light-primary"
                      type="button"
                      @click="modalEditProposalValidate()"
                    >
                      Enregistrer
                    </button>
                  </div>
                </div>
                <!--end::Actions-->
              </div>
              <!--end::Form-->
            </div>
            <!--end::Content0-->
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--end::Modal Edit Tender-->
</template>

<script>
import ProgressCard from "@/views/_core/components/ProgressCard";
import ProjectBreadcrumbComponent from "@/views/project/ProjectBreadcrumbComponent";
import kalmStore from "@/store";
import PrintHeader from "@/views/project/tender/PrintHeader";
import { ALL_CONTACTS_QUERY } from "@/views/contacts/data/contacts_graphql";
import {
  CURRENT_USER_QUERY,
  GET_PROPOSALS_FOR_PROJECT,
  MUTATION_TENDER_PROPOSAL_UPDATE_CHIFFRAGE,
  MUTATION_TENDER_HIDE_PROPOSAL,
  GET_PROJECT_BY_ID,
} from "@/graphql/graphql";
import readXlsxFile from "read-excel-file";
import { sortLots } from "@/utils/sort";

export default {
  name: "TenderPage",
  components: { ProgressCard, ProjectBreadcrumbComponent, PrintHeader },
  apollo: {
    contacts: {
      query: ALL_CONTACTS_QUERY,
    },
    currentUser: {
      query: CURRENT_USER_QUERY,
    },
    proposals: {
      query: GET_PROPOSALS_FOR_PROJECT,
      variables() {
        return {
          projectId: kalmStore.getters.currentProjectFocus,
        };
      },
    },
    project: {
      query: GET_PROJECT_BY_ID,
      variables() {
        return {
          id: kalmStore.getters.currentProjectFocus,
        };
      },
    },
  },
  computed: {
    isModalActive: function () {
      return kalmStore.state.display.profileToggled || this.modals.editProposal.show;
    },
    lots() {
      const lots = this.project?.lots || [];
      lots.forEach((lot) => {
        this.showLots[lot.id] = true;
      });
      return lots;
    },
    currentProjectFocus() {
      return kalmStore.getters.currentProjectFocus;
    },
    currentProject() {
      return this.project;
    },
    card_count_proposals_created() {
      if (!this.proposals) return 0;
      return this.proposals.length;
    },
    card_count_proposals_sent() {
      if (!this.proposals) return 0;

      let ret = 0;
      this.proposals.forEach((p) => {
        ret += JSON.parse(p.steps).find((s) => s.id === "1")?.status ? 1 : 0;
      });
      return ret;
    },
    card_count_proposals_received() {
      if (!this.proposals) return 0;

      let ret = 0;
      this.proposals.forEach((p) => {
        ret += JSON.parse(p.steps).find((s) => s.id === "2")?.status ? 1 : 0;
      });
      return ret;
    },
  },
  methods: {
    getProposalById(proposalId) {
      if (!this.proposals) return null;

      return this.proposals.find((p) => p.id === proposalId);
    },
    getChiffragesForLot(lot) {
      return this.proposals[0]?.chiffrages
        .filter((c) => c.lot.id === lot.id)
        .sort(function (a, b) {
          if (a.order < b.order) {
            return -1;
          }
          if (a.order > b.order) {
            return 1;
          }
          return 0;
        });
    },
    getParentLot(lot) {
      return lot.number.includes(".")
        ? this.lots.find((l) => l.number === lot.number.substring(0, lot.number.lastIndexOf(".")))
        : null;
    },
    getTitleStyleForLot(lot) {
      if (lot.number.match(/\./g)) {
        return "text-color-gray-700 ms-" + (lot.number.match(/\./g) || []).length * 4;
      } else {
        return "ms-0";
      }
    },
    lotHasChildrenOrOuvrages(lot) {
      if (!this.proposals) return true;

      return (
        this.lots.find((l) => l.number.startsWith(lot.number + ".")) ||
        this.proposals[0]?.chiffrages.filter((c) => c?.lot?.id === lot?.id).length !== 0
      );
    },
    toggleOrUntoggleAllLots() {
      if (this.toggledLots.length > 0) {
        this.toggledLots = [];
      } else {
        this.currentProject.lots.forEach((l) => {
          this.toggledLots.push(l.id);
        });
      }
    },
    tenderImportFromExcelLoad($event) {
      // reset import values
      this.tenderImportFromExcelCancel(true);

      // iterate on each line (=chiffrage) of each sheet (=lot)
      if ($event.target.files[0]) {
        this.lots.forEach((l) => {
          const sheetName = "Lot " + l.number;
          try {
            readXlsxFile($event.target.files[0], { sheet: sheetName }).then((rows) => {
              rows.forEach((row, index) => {
                try {
                  if (index > 3) {
                    // the 4 first lines are headers, not data
                    this.tenderImportFromExcel.chiffrages.push({
                      chiffrageId: row[1],
                      quantity: row[6],
                      unit: row[7],
                      unitPrice: row[8],
                      value: row[9],
                      // TODO : add VAT and VAT rate
                    });
                    if (row[6]) {
                      this.tenderImportFromExcel.countQuantity++;
                    }
                    if (row[7]) {
                      this.tenderImportFromExcel.countUnit++;
                    }
                    if (row[8]) {
                      this.tenderImportFromExcel.countUnitPrice++;
                    }
                    if (row[9]) {
                      this.tenderImportFromExcel.countValue++;
                    }
                  }
                } catch (e) {
                  console.error("erreur lors de l'import d'un des ouvrages :", e);
                }
              });
            });
          } catch (error) {
            console.error("erreur lors de l'import d'un des lots :", error);
            this.tenderImportFromExcel.error = true;
          }
        });
        this.tenderImportFromExcel.ready = true;
      } else {
        this.tenderImportFromExcel.error = true;
        this.tenderImportFromExcel.ready = false;
      }
    },
    tenderImportFromExcelValidate() {
      this.tenderImportFromExcel.chiffrages.forEach((c) => {
        let identifier = null;
        console.log("test tenderImportFromExcelValidate - c", c, c.ouvrageId, c.chiffrageId);
        if (c.ouvrageId && this.modals.editProposal?.chiffrages[c.ouvrageId] != null) {
          identifier = c.ouvrageId;
        } else if (c.chiffrageId && this.modals.editProposal?.chiffrages[c.chiffrageId] != null) {
          identifier = c.chiffrageId;
        }

        if (identifier) {
          if (c?.quantity || c?.quantity === 0) this.modals.editProposal.chiffrages[identifier].quantity = c.quantity;
          if (c?.unit) this.modals.editProposal.chiffrages[identifier].unit = c.unit;
          if (c?.unitPrice || c?.unitPrice === 0)
            this.modals.editProposal.chiffrages[identifier].unitPrice = c.unitPrice;
          if (c?.value || c?.value === 0) this.modals.editProposal.chiffrages[identifier].value = c.value.toFixed(2);

          if ((this.isChiffrageSumIncoherent(identifier) && c.value) || !c?.value) {
            this.updateChiffrageValue(identifier);
          }
        }
      });

      this.tenderImportFromExcel.ready = false;
      this.tenderImportFromExcelCancel(true);
    },
    tenderImportFromExcelCancel(keepFile = false) {
      if (!keepFile) {
        this.$refs.consultationFile.value = null;
      }
      this.tenderImportFromExcel.countQuantity = 0;
      this.tenderImportFromExcel.countUnit = 0;
      this.tenderImportFromExcel.countUnitPrice = 0;
      this.tenderImportFromExcel.countValue = 0;
      this.tenderImportFromExcel.error = false;
      this.tenderImportFromExcel.chiffrages = [];
      this.tenderImportFromExcel.ready = false;
    },
    handleMouseWheelOnNumberInput($event) {
      this.$nextTick(() => {
        if ($event.target === document.activeElement) {
          $event.preventDefault();
        }
      });
    },
    updateChiffrageValue(ouvrageId) {
      const unitPrice = this.modals.editProposal.chiffrages[ouvrageId].unitPrice;
      const quantity = this.modals.editProposal.chiffrages[ouvrageId].quantity;

      if ((unitPrice || unitPrice === 0) && (quantity || quantity === 0)) {
        this.modals.editProposal.chiffrages[ouvrageId].value = (parseFloat(unitPrice) * parseFloat(quantity)).toFixed(
          2
        );
      }
    },
    isChiffrageSumIncoherent(ouvrageId) {
      return (
        !!this.modals.editProposal.chiffrages[ouvrageId].unitPrice &&
        !!this.modals.editProposal.chiffrages[ouvrageId].quantity &&
        (
          parseFloat(this.modals.editProposal.chiffrages[ouvrageId].unitPrice) *
          parseFloat(this.modals.editProposal.chiffrages[ouvrageId].quantity)
        ).toFixed(2) !== parseFloat(this.modals.editProposal.chiffrages[ouvrageId].value).toFixed(2)
      );
    },
    getLotsForProposalById(proposalId) {
      const proposal = this.getProposalById(proposalId);
      let lots = [];
      if (proposal?.lots) {
        lots = JSON.parse(JSON.stringify(proposal.lots)).sort(sortLots);
      }
      return lots;
    },
    hideProposal(proposalId, hide) {
      this.$apollo.mutate({
        mutation: MUTATION_TENDER_HIDE_PROPOSAL,
        variables: {
          proposalId,
          hide,
        },
        optimisticResponse: {
          __typename: "Mutation",
          tenderHideProposal: {
            __typename: "TenderProposal",
            id: proposalId,
            hide: hide,
          },
        },
      });
    },
    printTender() {
      this.printOptions.title = "Consultation des entreprises";
      this.printOptions.subtitle = "Synthèse comparative";
      this.printOptions.text = "";
      this.printOptions.hideProposalsFromPrint = false;

      this.$nextTick(() => this.printDocument());
    },
    printEstimation() {
      this.printOptions.title = "Étude estimative des prix";
      this.printOptions.subtitle = "";
      this.printOptions.text =
        "Ce document est une estimation non contractuelle, il est présenté à titre informatif uniquement.";
      this.printOptions.hideProposalsFromPrint = true;

      this.$nextTick(() => this.printDocument());
    },
    printDocument() {
      window.print();
    },
    modalEditProposalShow(id) {
      this.modals.editProposal.id = id;

      let copyChiffrages = JSON.parse(JSON.stringify(this.getProposalById(id).chiffrages));

      this.modals.editProposal.chiffrages = {};
      copyChiffrages.forEach((c) => {
        this.modals.editProposal.chiffrages[c.ouvrageId] = c;
      });

      this.modals.editProposal.lotGlobalValues = {};
      this.lots.forEach((l) => {
        this.modals.editProposal.lotGlobalValues[l.id] = JSON.parse(this.getProposalById(id).lotGlobalValues)[l.id];
      });

      this.modals.editProposal.vatDefaultValue = this.getProposalById(id).vatDefaultValue;
      this.modals.editProposal.name = this.getProposalById(id).name;
      this.modals.editProposal.show = true;
    },
    modalEditProposalClose() {
      this.modals.editProposal.show = false;
      this.modals.editProposal.vatDefaultValue = "";
      this.modals.editProposal.id = "";
    },
    async modalEditProposalValidate() {
      const proposalId = this.modals.editProposal.id;
      const newChiffrage = JSON.stringify({
        chiffrages: Object.values(this.modals.editProposal.chiffrages), // we create an array of chiffrages from the editProposal.chiffrages object
        lotGlobalValues: this.modals.editProposal.lotGlobalValues,
      });

      await this.$apollo.mutate({
        mutation: MUTATION_TENDER_PROPOSAL_UPDATE_CHIFFRAGE,
        variables: {
          proposalId,
          newChiffrage,
        },
        refetchQueries: [
          {
            query: GET_PROPOSALS_FOR_PROJECT,
            variables: {
              projectId: kalmStore.getters.currentProjectFocus,
            },
          },
        ],
      });

      this.modalEditProposalClose();
    },
    proposalSubtotalForChiffrageUpdateModal(lotId) {
      const proposalId = this.modals.editProposal.id;
      const proposal = this.getProposalById(proposalId);

      if (proposal.lots.map((l) => l.id).includes(lotId)) {
        let lotSubTotal = 0;

        if (this.modals.editProposal.lotGlobalValues[lotId]?.sum) {
          lotSubTotal = JSON.parse(this.modals.editProposal.lotGlobalValues[lotId].sum);
        } else {
          lotSubTotal = Object.values(this.modals.editProposal.chiffrages)
            .filter((c) => c.lot.id === lotId)
            .map((c) => {
              const ret = parseFloat(c?.value);
              return isNaN(ret) ? 0 : parseFloat(c?.value);
            })
            .reduce((a, b) => a + b, 0);

          const masterLot = proposal.lots.find((l) => l.id === lotId);

          if (
            masterLot &&
            proposal.lots.filter(
              (l) =>
                l.number.startsWith(masterLot.number + ".") &&
                l.number !== masterLot.number &&
                l.id !== lotId &&
                l.number.split(".").length - 1 === masterLot.number.split(".").length
            ).length
          ) {
            const subLots = proposal.lots.filter(
              (l) =>
                l.number.startsWith(masterLot.number + ".") &&
                l.number !== masterLot.number &&
                l.id !== lotId &&
                l.number.split(".").length - 1 === masterLot.number.split(".").length
            );
            for (const x in subLots) {
              const l = subLots[x];
              lotSubTotal += this.proposalSubtotalForChiffrageUpdateModal(proposalId, l.id);
            }
          }
        }
        return lotSubTotal;
      } else {
        return 0;
      }
    },
    proposalSubtotal(proposalId, lotId) {
      const proposal = this.getProposalById(proposalId);

      //if(proposal.lots.map(l => l.id).includes(lotId)){
      let lotSubTotal = 0;

      if (!this.showLots[lotId]) {
        lotSubTotal = 0;
      } else if (JSON.parse(proposal.lotGlobalValues)[lotId]?.sum) {
        lotSubTotal = JSON.parse(JSON.parse(proposal.lotGlobalValues)[lotId].sum);
      } else {
        // Proposal includes lot, sum its chiffrages
        if (proposal.lots.map((l) => l.id).includes(lotId)) {
          lotSubTotal = proposal.chiffrages
            .filter((c) => c.lot.id === lotId)
            .map((c) => {
              const ret = parseFloat(c?.value);
              return isNaN(ret) ? 0 : parseFloat(c?.value);
            })
            .reduce((a, b) => a + b, 0);
        }

        // Sum all sous-lots
        const masterLot = this.lots.find((l) => l.id === lotId);
        if (
          masterLot &&
          this.lots.filter(
            (l) =>
              l.number.startsWith(masterLot.number + ".") &&
              l.number !== masterLot.number &&
              l.id !== lotId &&
              l.number.split(".").length - 1 === masterLot.number.split(".").length
          ).length
        ) {
          const subLots = this.lots.filter(
            (l) =>
              l.number.startsWith(masterLot.number + ".") &&
              l.number !== masterLot.number &&
              l.id !== lotId &&
              l.number.split(".").length - 1 === masterLot.number.split(".").length
          );
          for (const x in subLots) {
            const l = subLots[x];
            lotSubTotal += this.proposalSubtotal(proposalId, l.id);
          }
        }
      }
      return lotSubTotal;
      /*}
      else {
        // Lot is not part of proposal, we inspect its sub lots
        return 0
      }*/
    },
    proposalSubtotalFormatted(proposalId, lotId) {
      const proposal = this.getProposalById(proposalId);

      if (!this.showLots[lotId]) {
        return "-";
      } else if (proposal.lots.map((l) => l.id).includes(lotId)) {
        let lotSubTotal = this.proposalSubtotal(proposalId, lotId);

        return lotSubTotal.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " € HT";
      } else {
        return proposal?.contact ? "NC" : "NC";
      }
    },
    proposalTotal(proposalId) {
      if (!this.lots) return "0";

      let ret = 0;
      this.lots
        .filter((l) => l.number.split(".").length - 1 === 0)
        .forEach((l) => {
          ret += this.proposalSubtotal(proposalId, l.id);
        });
      return ret.toFixed(2);
    },
    proposalVatSubtotal(proposalId, lotId) {
      const proposal = this.getProposalById(proposalId);

      let lotSubTotal = 0;

      if (!this.showLots[lotId]) {
        lotSubTotal = 0;
      } else if (JSON.parse(proposal.lotGlobalValues)[lotId]?.sum) {
        // defaults to proposal vat if lot vat is not defined
        const lotVatRate =
          JSON.parse(proposal.lotGlobalValues)[lotId].vat === null ||
          JSON.parse(proposal.lotGlobalValues)[lotId].vat === "" ||
          isNaN(JSON.parse(proposal.lotGlobalValues)[lotId].vat)
            ? proposal.vatDefaultValue
            : JSON.parse(proposal.lotGlobalValues)[lotId].vat;
        lotSubTotal = (JSON.parse(proposal.lotGlobalValues)[lotId].sum * lotVatRate) / 100;
      } else {
        // includes vat sum of chiffrages if lot is included
        if (proposal.lots.map((l) => l.id).includes(lotId)) {
          lotSubTotal = proposal.chiffrages
            .filter((c) => c.lot.id === lotId)
            .map((c) => {
              const ret = parseFloat(c?.vatValue);
              return isNaN(ret) ? 0 : parseFloat(c?.vatValue);
            })
            .reduce((a, b) => a + b, 0);
        }

        const masterLot = this.lots.find((l) => l.id === lotId);

        if (
          masterLot &&
          this.lots.filter(
            (l) =>
              l.number.startsWith(masterLot.number + ".") &&
              l.number !== masterLot.number &&
              l.id !== lotId &&
              l.number.split(".").length - 1 === masterLot.number.split(".").length
          ).length
        ) {
          const subLots = this.lots.filter(
            (l) =>
              l.number.startsWith(masterLot.number + ".") &&
              l.number !== masterLot.number &&
              l.id !== lotId &&
              l.number.split(".").length - 1 === masterLot.number.split(".").length
          );
          for (const x in subLots) {
            const l = subLots[x];
            lotSubTotal += this.proposalVatSubtotal(proposalId, l.id);
          }
        }
      }
      return lotSubTotal;
    },
    proposalVatTotal(proposalId) {
      if (!this.lots) return "0";

      let ret = 0;
      this.lots
        .filter((l) => l.number.split(".").length - 1 === 0)
        .forEach((l) => {
          ret += this.proposalVatSubtotal(proposalId, l.id);
        });
      return ret.toFixed(2);
    },
    toggleLot(lotId) {
      if (this.toggledLots.includes(lotId)) {
        this.toggledLots = [...this.toggledLots.filter((l) => l !== lotId)];
      } else {
        this.toggledLots.push(lotId);
      }
    },
    toggleHideLot(lotId) {
      this.showLots[lotId] = !this.showLots[lotId];
    },
    lotToggled(lotId) {
      return this.toggledLots.includes(lotId);
    },
    showOuvrageInfoTooltip(ouvrageId, status) {
      this.tooltip = status ? ouvrageId : "";
    },
    getProposalChiffrageForOuvrage(proposal, lot, ouvrageId) {
      const chiffrageValue = proposal.chiffrages.find((c) => c.ouvrageId === ouvrageId)?.value;
      if (proposal.lots.map((l) => l.id).includes(lot)) {
        return (
          parseFloat(chiffrageValue || "0")
            .toFixed(2)
            .replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " € HT"
        );
      } else {
        return "-";
      }
    },
  },
  data() {
    return {
      printCustomText: "",
      showPrintCustomText: false,
      lines: [],
      contacts: [],
      showLots: {},
      proposals: [],
      modals: {
        editProposal: {
          show: false,
          name: "",
          showDetails: false,
          vatDefaultValue: "",
          id: "",
          chiffrages: {},
          lotGlobalValues: {},
        },
      },
      printOptions: {
        title: "Consultation des entreprises",
        subtitle: "Synthèse comparative",
        hideProposalsFromPrint: false,
        text: "",
      },
      tenderImportFromExcel: {
        error: false,
        chiffrages: [],
        countQuantity: 0,
        countUnit: 0,
        countUnitPrice: 0,
        countValue: 0,
      },
      tooltip: "",
      toggledLots: [],
    };
  },
};
</script>

<style scoped>
.blur-content {
  filter: blur(5px);
}

.edit-proposal-button {
  cursor: pointer;
}
.edit-proposal-button:hover,
.edit-proposal-button:hover i {
  cursor: pointer;
  background-color: white !important;
  color: gray !important;
}

@media print {
  .w-print-200 {
    min-width: 200px !important;
    text-wrap: stable;
    word-wrap: break-word;
  }

  .print-hidden {
    display: none !important;
  }

  .print-noindent {
    margin-left: 0 !important;
  }

  tr,
  th {
    page-break-after: auto;
    page-break-before: auto;
    page-break-inside: avoid;
  }
}

@media screen {
  .web-sticky {
    position: sticky;
    top: 60px;
  }
}
</style>
