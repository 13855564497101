<template>
  <div>
    <label class="col-form-label">{{ title }}</label>
    <select v-model="selectedContactIdInternal" class="form-select form-select-solid">
      <option v-if="showDoNothingOption" value="11111111-1111-1111-1111-111111111111">Ne pas changer</option>
      <option :value="null">Non attribué</option>
      <option value="00000000-0000-0000-0000-000000000000">Attribuer à tous</option>
      <option v-for="contact in contacts" :key="contact.id" :value="contact.id">
        <span v-if="contact?.company && contact?.name">{{ contact.company }} ({{ contact.name }})</span>
        <span v-else-if="contact?.company">{{ contact.company }}</span>
        <span v-else-if="contact?.name">{{ contact.name }}</span>
      </option>
    </select>
  </div>
</template>

<script>
export default {
  name: "ContactSelect",
  props: {
    title: String,
    modelValue: {
      type: String,
      default: "",
    },
    contacts: {
      type: Array,
      default: () => [],
    },
    showDoNothingOption: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    selectedContactIdInternal: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
};
</script>
