<template xmlns:title="http://www.w3.org/1999/xhtml">
  <node-view-wrapper class="vue-component">
    <div @mouseleave="this.hover = false" @mouseover="this.hover = true">
      <div class="mt-3 mb-3 ms-8 print-border" style="break-inside: avoid">
        <div class="ps-0 mt-0">
          <div class="card lot-component-shadow" style="position: relative">
            <!--begin::Body-->
            <div class="p-0 card-body">
              <!--begin::Section-->
              <div class="print-margins py-6 ps-0 pe-5 align-items-center justify-content-between d-flex">
                <!-- LEFT HOVER ACTIONS -->
                <div class="position-absolute d-flex align-content-center" style="left: -50px">
                  <div
                    :style="'visibility: ' + (this.hover ? 'visible' : 'hidden')"
                    class="ouvrage-actions-left ouvrage-action-plus"
                    contenteditable="false"
                    role="button"
                    tabindex="0"
                    title="Ajouter une ligne"
                    @click="plusAction()"
                  >
                    <svg style="width: 15px; height: 100%; display: block" viewBox="0 0 16 16">
                      <path
                        d="M7.977 14.963c.407 0 .747-.324.747-.723V8.72h5.362c.399 0 .74-.34.74-.747a.746.746 0 00-.74-.738H8.724V1.706c0-.398-.34-.722-.747-.722a.732.732 0 00-.739.722v5.529h-5.37a.746.746 0 00-.74.738c0 .407.341.747.74.747h5.37v5.52c0 .399.332.723.739.723z"
                      ></path>
                    </svg>
                  </div>
                  <div
                    :style="'visibility: ' + (this.hover ? 'visible' : 'hidden')"
                    class="ouvrage-actions-left drag-handle"
                    contenteditable="false"
                    data-drag-handle
                    draggable="true"
                    role="button"
                    tabindex="0"
                    title="Déplacer l'ouvrage"
                  >
                    <svg
                      style="width: 15px; height: 100%; display: block"
                      viewBox="0 0 16 16"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4 14c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2zM2 6C.9 6 0 6.9 0 8s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0-6C.9 0 0 .9 0 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm6 4c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"
                        fill-opacity="0.2"
                      />
                    </svg>
                  </div>
                </div>

                <!-- MODE - FULL -->
                <div class="digital-margins flex-grow-1 text-break">
                  <!-- Node number & collapsed mode badges -->
                  <div class="d-flex">
                    <span class="text-gray-500 font-monospace align-baseline fs-6 mb-1">{{ this.propNumero }}</span>
                    <div v-if="this.isDescriptionDisplayModeCollapsed" class="d-flex print-hidden">
                      <span v-if="this.propQt" class="badge badge-light">
                        Qt: {{ this.propQt }} {{ this.propUnit }}
                      </span>
                      <span v-if="this.propUnitPrice" class="ms-2 badge badge-light">
                        PU: {{ this.propUnitPrice }}€
                      </span>
                      <span v-if="this.propTotalHT" class="ms-2 badge badge-light">
                        TTL HT: {{ this.propTotalHT }}€
                      </span>
                      <span v-if="this.propVatRate" class="ms-2 badge badge-light"> TVA: {{ this.propVatRate }}% </span>
                    </div>
                  </div>

                  <!-- Node main content -->
                  <div class="w-100" style="">
                    <node-view-content
                      id="ouvrage-node-content"
                      ref="content"
                      :style="
                        isDescriptionDisplayModeCollapsed
                          ? 'text-overflow: ellipsis; overflow: hidden; height: 20px; max-height: 20px;'
                          : ''
                      "
                      class="content p-0"
                    />
                  </div>
                  <!-- Print Info -->
                  <div class="print-info">
                    <div v-if="this.propQt" class="fw-bold">
                      Quantité : <span class="fw-normal">{{ this.propQt }} {{ this.propUnit }}</span>
                    </div>
                    <div v-if="this.propUnitPrice" class="fw-bold">
                      Prix unitaire : <span class="fw-normal">{{ this.propUnitPrice }} €</span>
                    </div>
                    <div v-if="this.propVatRate" class="fw-bold">
                      TVA : <span class="fw-normal">{{ this.propVatRate }} %</span>
                    </div>
                    <div v-if="this.propTotalHT" class="fw-bold">
                      TTL HT : <span class="fw-normal">{{ this.propTotalHT }} %</span>
                    </div>
                  </div>
                  <!-- Actionable node variables -->
                  <div v-if="!isDescriptionDisplayModeCollapsed" class="row print-hidden">
                    <!--  PAS D'EMPLACEMENT EN BIBLIO  -->

                    <!--  QUANTITE  -->
                    <div v-if="this.propQt && isNaN(this.propQt)" class="col-12 cols-sm-3 align-items-center">
                      <div class="mt-3 fs-8 text-muted">
                        Quantité :
                        <input
                          v-model="this.propQt"
                          class="field-qt form-control text-start bg-white placeholder-gray-500 w-100 text-gray-800 py-2 px-3 m-0 fs-6"
                          type="text"
                        />
                      </div>
                    </div>
                    <!--  QUANTITE / UNITE  -->
                    <div v-else class="col-12 col-sm-3">
                      <div class="mt-3 fs-8 text-muted">
                        Quantité
                        <div class="input-group">
                          <input
                            v-model="this.propQt"
                            class="form-control text-start bg-white placeholder-gray-500 text-gray-800 py-2 px-3 m-0 fs-6"
                            type="number"
                          />
                          <input
                            v-if="isFirefox"
                            v-model="this.propUnit"
                            class="form-control text-start bg-white placeholder-gray-500 text-gray-800 py-2 px-3 m-0 border-start-0"
                            placeholder="unité"
                          />
                          <select
                            v-else
                            v-model="this.propUnit"
                            class="form-control form-select text-start bg-white placeholder-gray-500 text-gray-800 py-2 px-3 m-0 border-start-0"
                          >
                            <option selected>ens</option>
                            <option>u</option>
                            <option>m</option>
                            <option>m2</option>
                            <option>m3</option>
                            <option>ml</option>
                            <option>g</option>
                            <option>kg</option>
                            <option>t</option>
                            <option>forfait</option>
                            <option hidden>m²</option>
                            <option hidden>m³</option>
                            <option>jours</option>
                            <option>mois</option>
                            <option>h</option>
                            <option></option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <!--  PRIX UNITAIRE  -->
                    <div class="col-12 col-sm-3 align-items-center">
                      <div class="mt-3 fs-8 text-muted">
                        Prix unitaire (HT)
                        <input
                          v-model="this.propUnitPrice"
                          class="field-qt form-control text-start bg-white placeholder-gray-500 w-100 text-gray-800 py-2 px-3 m-0 fs-6"
                          type="number"
                        />
                      </div>
                    </div>

                    <!--  TVA  -->
                    <div class="col-12 col-sm-3 align-items-center">
                      <div class="mt-3 fs-8 text-muted">
                        Taux TVA
                        <div class="input-group">
                          <input
                            v-model="this.propVatRate"
                            class="field-qt form-control text-start bg-white placeholder-gray-500 text-gray-800 py-2 px-3 m-0 fs-6"
                            type="number"
                          />
                          <span class="input-group-text py-2">%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- RIGHT BUTTON ACTIONS -->
                <div
                  class="ouvrage-actions position-absolute end-0 top-0 row-cols-1 w-50px h-100 align-content-center justify-content-center"
                >
                  <button
                    class="btn btn-icon btn-sm btn-outline-secondary"
                    title="Dupliquer l'ouvrage"
                    @click="duplicateNode()"
                  >
                    <i class="fa fa-light fa-copy"></i>
                  </button>
                  <button
                    class="btn btn-icon btn-sm btn-outline-secondary"
                    title="Supprimer l'ouvrage"
                    @click="deleteNode()"
                  >
                    <i class="bi bi-trash"></i>
                  </button>
                </div>
              </div>
            </div>
            <!--end::Body-->
          </div>
        </div>
      </div>
    </div>
  </node-view-wrapper>
  <!--  <node-view-wrapper class="vue-component">
    <div @mouseover="this.hover = true"
         @mouseleave="this.hover = false"
    >
      <div class="row g-5 mt-3 mb-3 ms-8 print-border"
           style="break-inside: avoid;">
        <div class="col-xl-12 mt-0">
          <div class="card lot-component-shadow" style="position: relative;">
            &lt;!&ndash;begin::Body&ndash;&gt;
            <div class="p-0 card-body row">
              &lt;!&ndash;begin::Section&ndash;&gt;
              <div class="print-margins col-12 col-md-10 col-xl-11 pb-6 pt-8 ps-0 pe-10 d-flex align-items-center me-60px">
                <div class="ouvrage-left-actions d-flex align-content-center">
                  <div role="button" tabindex="0"
                       @click="plusAction()"
                       contenteditable="false"
                       title="Ajouter une ligne"
                       :style="'visibility: ' + (this.hover ? 'visible' : 'hidden')"
                       class="ouvrage-actions-left ouvrage-action-plus">
                    <svg viewBox="0 0 16 16"
                         style="width: 15px; height: 100%; display: block;">
                      <path
                          d="M7.977 14.963c.407 0 .747-.324.747-.723V8.72h5.362c.399 0 .74-.34.74-.747a.746.746 0 00-.74-.738H8.724V1.706c0-.398-.34-.722-.747-.722a.732.732 0 00-.739.722v5.529h-5.37a.746.746 0 00-.74.738c0 .407.341.747.74.747h5.37v5.52c0 .399.332.723.739.723z"></path>
                    </svg>
                  </div>
                  <div
                      role="button" tabindex="0"
                      contenteditable="false"
                      draggable="true"
                      data-drag-handle
                      title="Déplacer l'ouvrage"
                      class="ouvrage-actions-left drag-handle"
                      :style="'visibility: ' + (this.hover ? 'visible' : 'hidden')"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"
                         style="width: 15px; height: 100%; display: block;">
                      <path fill-opacity="0.2" d="M4 14c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2zM2 6C.9 6 0 6.9 0 8s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0-6C.9 0 0 .9 0 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm6 4c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" />
                    </svg>
                  </div>
                </div>

                &lt;!&ndash;begin::Content&ndash;&gt;
                <div class="w-100">
                  <node-view-content id="ouvrage-node-content" ref="content" class="content p-0" />
                  <div class="row print-hidden">
                    <div class="col-6 align-items-center">
                    </div>
                    <div v-if="this.propQt && isNaN(this.propQt)" class="col-3 align-items-center">
                      <div class="mt-3 fs-8 text-muted">
                        Quantité :
                        <input type="text" class="field-qt form-control text-start bg-white placeholder-gray-500 w-100 text-gray-800 py-2 px-3 m-0 fs-6" v-model="this.propQt">
                      </div>
                    </div>
                    <div v-else class="col-3">
                      <div class="mt-3 fs-8 text-muted">
                        Quantité :
                        <div class="input-group">
                          <input class="form-control text-start bg-white placeholder-gray-500 text-gray-800 py-2 px-3 m-0 fs-6" type="number" v-model="this.propQt">
                          <select class="form-control form-select text-start bg-white placeholder-gray-500 text-gray-800 py-2 px-3 m-0 border-start-0"
                                  v-model="this.propUnit">
                            <option selected>ens</option>
                            <option>u</option>
                            <option>m</option>
                            <option>m2</option>
                            <option>m3</option>
                            <option>ml</option>
                            <option>t</option>
                            <option>forfait</option>
                            <option hidden>m²</option>
                            <option hidden>m³</option>
                            <option>jours</option>
                            <option>mois</option>
                            <option>h</option>
                            <option></option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="col-3 align-items-center">
                      <div class="mt-3 fs-8 text-muted">
                        Prix unitaire :
                        <input type="number" class="field-qt form-control text-start bg-white placeholder-gray-500 w-100 text-gray-800 py-2 px-3 m-0 fs-6" v-model="this.propUnitPrice">
                      </div>
                    </div>
                  </div>
                </div>
                &lt;!&ndash;end::Content&ndash;&gt;
                <div class="ouvrage-actions position-absolute end-0 row-cols-1 w-60px">

                  <button class="btn btn-outline-secondary"
                          @click="this.duplicateNode()">
                    <i class="fa fa-light fa-copy"></i>
                  </button>
                  <button class="btn btn-outline-secondary"
                          @click="this.deleteNode()">
                    <i class="bi bi-trash"></i>
                  </button>
                </div>
              </div>
            </div>
            &lt;!&ndash;end::Body&ndash;&gt;
          </div>
        </div>
      </div>
    </div>
  </node-view-wrapper>-->
</template>

<script>
import kalmStore from "@/store";
import { NodeViewContent, NodeViewWrapper } from "@tiptap/vue-3";
import { toast } from "vue3-toastify";

export default {
  name: "BiblioOuvrageComponent",
  components: {
    NodeViewWrapper,
    NodeViewContent,
  },
  props: {
    node: {
      type: Object,
      required: true,
      default: () => ({
        qt: "",
        unit: "",
        id: "",
        unitPrice: "",
        vatRate: "",
        totalHT: "",
      }),
    },
    updateAttributes: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      hover: false,
      lots: [],
    };
  },
  computed: {
    propUnitPrice: {
      get() {
        return this.node.attrs.unitPrice;
      },
      set(value) {
        this.updateAttributes({
          unitPrice: value,
        });
      },
    },
    propTotalHT: {
      get() {
        return this.node.attrs.totalHT;
      },
      set(value) {
        this.updateAttributes({
          totalHT: value,
        });
      },
    },
    propVatRate: {
      get() {
        return this.node.attrs.vatRate || "";
      },
      set(value) {
        this.updateAttributes({
          vatRate: value,
        });
      },
    },
    propQt: {
      get() {
        return this.node.attrs.qt;
      },
      set(value) {
        this.updateAttributes({
          qt: value,
        });
      },
    },
    propUnit: {
      get() {
        return this.node.attrs.unit;
      },
      set(value) {
        this.updateAttributes({
          unit: value,
        });
      },
    },
    isDescriptionDisplayModeCollapsed() {
      return kalmStore.state.display.descriptionMode === "collapsed";
    },
    isFirefox: () => {
      return navigator.userAgent.toLowerCase().indexOf("firefox") > -1;
    },
  },
  methods: {
    deleteNode() {
      const { editor, getPos, node } = this;

      const from = getPos();
      const to = from + node.nodeSize;

      editor.commands.deleteRange({ from, to });
      editor.commands.focus();

      toast.success("1 ouvrage supprimé");
    },
    duplicateNode() {
      const end = this.getPos() + this.node.nodeSize;

      const ouvrageCopy = {
        type: "lotOuvrage",
        attrs: {
          qt: this.propQt,
          unit: this.propUnit,
          unitPrice: this.unitPrice,
          totalHT: this.propTotalHT,
          vatRate: this.propVatRate,
        },
        content: JSON.parse(JSON.stringify(this.node.content.content)),
      };

      this.editor.chain().insertContentAt(end, ouvrageCopy).focus().run();
    },
    plusAction() {
      const { editor, getPos, node } = this;
      const end = getPos() + node.nodeSize;

      editor.chain().insertContentAt(end, { type: "lotParagraph", content: [] }).focus().run();
    },
    updateQt(event) {
      const newQt = event.target.value;
      this.updateAttributes({
        qt: newQt,
      });
    },
  },
};
</script>

<style>
#ouvrage-node-content p,
ul {
  margin-bottom: 7px !important;
}
</style>

<style scoped>
/*noinspection CssRedundantUnit*/
.lot-component-shadow {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.drag-handle {
  flex: 0 0 auto;
  order: 2;

  width: 20px;
  height: 24px;

  border-radius: 3px;
  pointer-events: auto;

  cursor: grab;
}

.ouvrage-actions-left {
  display: flex;
  gap: 10px;

  align-items: center;
  justify-content: center;

  width: 24px;
  height: 24px;

  border-radius: 3px;
  pointer-events: auto;
}

.ouvrage-action-plus {
  order: 1;
  transition: background 20ms ease-in 0s;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 24px;
  height: 24px;

  fill: rgba(55, 53, 47, 0.35);
  cursor: pointer;
  border-radius: 3px;
  pointer-events: auto;
}

.ouvrage-action-plus:hover {
  background: rgba(55, 53, 47, 0.08);
}

.print-info {
  display: none;
}

@media screen {
  div .digital-margins {
    margin-left: 40px;
    margin-right: 60px;
  }
}

@media print {
  div .digital-margins {
    margin-left: 0px;
    margin-right: 0px;
  }

  .lot-component-shadow {
    box-shadow: none;
  }

  .drag-handle {
    display: none;
  }

  .field-qt {
    border: 0;
  }

  div .print-margins {
    break-inside: avoid;
    margin-left: 0 !important;
    padding-left: 5px !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }

  .print-border {
    border-left: 1px solid lightgray;
    padding-left: 10px !important;
    margin-bottom: 20px !important;
    margin-top: 15px !important;
    margin-left: 5px !important;
  }

  .ouvrage-left-actions {
    display: none !important;
    visibility: hidden;
  }

  .print-hidden {
    display: none !important;
  }

  .print-info {
    display: block;
  }
}
</style>
