import Document from "@tiptap/extension-document";

import { Plugin } from "prosemirror-state";
import { mergeAttributes, Node } from "@tiptap/core";
import { StarterKit } from "@tiptap/starter-kit";
import { Paragraph } from "@tiptap/extension-paragraph";
import { v4 } from "uuid";
import { VueNodeViewRenderer } from "@tiptap/vue-3";
import { findParentNodeOfType } from "prosemirror-utils";

import BiblioParagraphComponent from "@/views/project/biblio/BiblioParagraphComponent";
import BiblioOuvrageComponent from "@/views/project/biblio/BiblioOuvrageComponent";
import BiblioTextComponent from "@/views/project/biblio/BiblioTextComponent";

export const biblioDoc = Document.extend({
  name: "biblioDoc",
  content: "(lotParagraph|lotText|lotOuvrage)+",
  extensions: [],
});

export const biblioOuvrage = Node.create({
  name: "lotOuvrage",
  priority: 10000,

  group: "lotContentGroup",

  extensions: [
    StarterKit.configure({
      paragraph: false,
    }),
    Paragraph.extend({
      priority: 9999,
    }),
  ],

  content: "heading (paragraph|heading|list)*",

  draggable: true,
  disableDropCursor: true,

  addAttributes() {
    return {
      qt: {
        type: String,
        default: "",
      },
      unit: {
        type: String,
        default: "",
      },
      unitPrice: {
        type: String,
        default: "",
      },
      vatRate: {
        type: String,
        default: "",
      },
      id: {
        type: String,
        default: null,
      },
    };
  },

  addProseMirrorPlugins() {
    return [
      new Plugin({
        appendTransaction: (transactions, oldState, newState) => {
          const newTr = newState.tr;
          let modified = false;

          newState.doc.descendants((node, pos) => {
            if (!!node.type && node.type.name === "lotOuvrage") {
              const { id, ...rest } = node.attrs;
              if (id === undefined || id === null || id === "") {
                // Adds a unique id to a node
                newTr.setNodeMarkup(pos, undefined, { id: v4(), ...rest });
                modified = true;
              }
            }
          });

          if (modified) {
            return newTr;
          }
        },
      }),
    ];
  },

  parseHTML() {
    return [
      {
        tag: "ouvrage",
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ["ouvrage", mergeAttributes(HTMLAttributes, { "data-type": "draggable-item" }), 0];
  },

  addNodeView() {
    return VueNodeViewRenderer(BiblioOuvrageComponent);
  },
});

export const biblioText = Node.create({
  name: "lotText",
  priority: 1200,

  group: "lotContentGroup",

  extensions: [
    StarterKit.configure({
      paragraph: false,
    }),
    Paragraph.extend({
      priority: 999,
    }),
  ],

  content: "(paragraph|heading|list)*",

  draggable: true,
  disableDropCursor: true,

  parseHTML() {
    return [
      {
        tag: "lot-text",
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ["lot-text", mergeAttributes(HTMLAttributes), 0];
  },

  addNodeView() {
    return VueNodeViewRenderer(BiblioTextComponent);
  },
});

export const biblioParagraph = Node.create({
  name: "lotParagraph",
  priority: 1000,

  extensions: [StarterKit],

  content: "inline*",
  group: "block",

  draggable: true,
  disableDropCursor: true,

  parseHTML() {
    return [
      {
        tag: "p",
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ["p", mergeAttributes(HTMLAttributes, { "data-type": "draggable-item" }), 0];
  },

  addNodeView() {
    return VueNodeViewRenderer(BiblioParagraphComponent);
  },

  addKeyboardShortcuts() {
    return {
      "Mod-Enter": () => this.editor.commands.addNewLine(),
    };
  },

  addCommands() {
    return {
      addNewLine:
        () =>
        ({ tr, chain }) => {
          const candidate =
            findParentNodeOfType(tr.doc.type.schema.nodes.lotOuvrage)(tr.selection) ||
            findParentNodeOfType(tr.doc.type.schema.nodes.lotText)(tr.selection) ||
            findParentNodeOfType(tr.doc.type.schema.nodes.lotParagraph)(tr.selection);

          const end = candidate.pos + candidate.node.nodeSize;
          return chain().insertContentAt(end, { type: "lotParagraph", content: [] }).focus().run();
        },
    };
  },
});
