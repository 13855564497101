<template>
  <KalmModal modalTitle="Tri des tâches" @close="close">
    <template v-slot:body>
      <div class="mt-4">
        <label class="form-label">Trier par :</label>
        <select v-model="data.sort1" class="form-select">
          <option value="">Tri par défaut</option>
          <option value="status__order">Statut (croissant)</option>
          <option value="-status__order">Statut (décroissant)</option>
          <option value="start_date">Date de début (croissante)</option>
          <option value="-start_date">Date de début (décroissante)</option>
          <option value="end_date">Date de fin (croissante)</option>
          <option value="-end_date">Date de fin (décroissante)</option>
          <option value="assigned_company__name">Entreprise (croissante)</option>
          <option value="-assigned_company__name">Entreprise (décroissante)</option>
          <option value="updated_date">Dernière modif (croissante)</option>
          <option value="-updated_date">Dernière modif (décroissante)</option>
          <option value="created_time">Date de création (croissante)</option>
          <option value="-created_time">Date de création (décroissante)</option>
          <option value="numero">Numéro de l'ouvrage (croissant)</option>
          <option value="-numero">Numéro de l'ouvrage (décroissant)</option>
          <option value="title">Titre de l'ouvrage (croissant)</option>
          <option value="-title">Titre de l'ouvrage (décroissant)</option>
        </select>
      </div>

      <div class="mt-2">
        <label class="form-label">Puis par :</label>
        <select v-model="data.sort2" class="form-select">
          <option value="">Tri par défaut</option>
          <option value="status__order">Statut (croissant)</option>
          <option value="-status__order">Statut (décroissant)</option>
          <option value="start_date">Date de début (croissante)</option>
          <option value="-start_date">Date de début (décroissante)</option>
          <option value="end_date">Date de fin (croissante)</option>
          <option value="-end_date">Date de fin (décroissante)</option>
          <option value="assigned_company__name">Entreprise (croissante)</option>
          <option value="-assigned_company__name">Entreprise (décroissante)</option>
          <option value="updated_date">Dernière modif (croissante)</option>
          <option value="-updated_date">Dernière modif (décroissante)</option>
          <option value="created_time">Date de création (croissante)</option>
          <option value="-created_time">Date de création (décroissante)</option>
          <option value="numero">Numéro de l'ouvrage (croissant)</option>
          <option value="-numero">Numéro de l'ouvrage (décroissant)</option>
          <option value="title">Titre de l'ouvrage (croissant)</option>
          <option value="-title">Titre de l'ouvrage (décroissant)</option>
        </select>
      </div>

      <div class="mt-2">
        <label class="form-label">Puis par :</label>
        <select v-model="data.sort3" class="form-select">
          <option value="">Tri par défaut</option>
          <option value="status__order">Statut (croissant)</option>
          <option value="-status__order">Statut (décroissant)</option>
          <option value="start_date">Date de début (croissante)</option>
          <option value="-start_date">Date de début (décroissante)</option>
          <option value="end_date">Date de fin (croissante)</option>
          <option value="-end_date">Date de fin (décroissante)</option>
          <option value="assigned_company__name">Entreprise (croissante)</option>
          <option value="-assigned_company__name">Entreprise (décroissante)</option>
          <option value="updated_date">Dernière modif (croissante)</option>
          <option value="-updated_date">Dernière modif (décroissante)</option>
          <option value="created_time">Date de création (croissante)</option>
          <option value="-created_time">Date de création (décroissante)</option>
          <option value="numero">Numéro de l'ouvrage (croissant)</option>
          <option value="-numero">Numéro de l'ouvrage (décroissant)</option>
          <option value="title">Titre de l'ouvrage (croissant)</option>
          <option value="-title">Titre de l'ouvrage (décroissant)</option>
        </select>
      </div>

      <div class="mt-2">
        <label class="form-label">Puis par :</label>
        <select v-model="data.sort4" class="form-select">
          <option value="">Tri par défaut</option>
          <option value="status__order">Statut (croissant)</option>
          <option value="-status__order">Statut (décroissant)</option>
          <option value="start_date">Date de début (croissante)</option>
          <option value="-start_date">Date de début (décroissante)</option>
          <option value="end_date">Date de fin (croissante)</option>
          <option value="-end_date">Date de fin (décroissante)</option>
          <option value="assigned_company__name">Entreprise (croissante)</option>
          <option value="-assigned_company__name">Entreprise (décroissante)</option>
          <option value="updated_date">Dernière modif (croissante)</option>
          <option value="-updated_date">Dernière modif (décroissante)</option>
          <option value="created_time">Date de création (croissante)</option>
          <option value="-created_time">Date de création (décroissante)</option>
          <option value="numero">Numéro de l'ouvrage (croissant)</option>
          <option value="-numero">Numéro de l'ouvrage (décroissant)</option>
          <option value="title">Titre de l'ouvrage (croissant)</option>
          <option value="-title">Titre de l'ouvrage (décroissant)</option>
        </select>
      </div>
    </template>
    <template v-slot:footer>
      <div>
        <button class="btn btn-lg btn-light" data-bs-dismiss="modal" type="button" @click="close">Annuler</button>
        <button class="btn btn-lg btn-primary ms-2" @click="save">Enregistrer</button>
      </div>
    </template>
  </KalmModal>
</template>

<script>
import KalmModal from "@/views/_core/components/KalmModal.vue";
export default {
  props: {
    taskSortOrder: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    KalmModal,
  },
  data() {
    return {
      data: {
        hideArchived: false,
        sort1: "",
        sort2: "",
        sort3: "",
        sort4: "",
      },
    };
  },
  mounted() {
    this.data.sort1 = this.taskSortOrder[0] || "";
    this.data.sort2 = this.taskSortOrder[1] || "";
    this.data.sort3 = this.taskSortOrder[2] || "";
    this.data.sort4 = this.taskSortOrder[3] || "";
  },
  methods: {
    close() {
      this.$emit("close");
    },
    save() {
      this.$emit("save", this.data);
    },
  },
};
</script>
