<template>
  <div class="h-100">
    <div class="d-flex mb-2">
      <ProjectBreadcrumbComponent
        :project_id="projectId"
        current_route="project.settings"
        title="Paramètres du projet"
      />
    </div>
    <div v-if="this.$apollo.loading" class="d-flex justify-content-center">
      <span class="spinner-border text-primary spinner-border my-12"></span>
    </div>
    <div v-else>
      <h3 class="mt-5">Suivi de chantier</h3>
      <div class="card my-4">
        <div class="card-body">
          <div class="d-flex justify-content-between align-items-center">
            <h5 class="card-title">Filtrage par défaut des tâches</h5>
            <p v-if="this.projectSettingsByProject.includedStatuses.length === 0" class="text-muted">
              Tous les status sont inclus
            </p>
            <div v-else>
              <p>Status inclus</p>
              <ul>
                <li v-for="status in this.projectSettingsByProject.includedStatuses" :key="status.id">
                  {{ status?.name }}
                </li>
              </ul>
            </div>
            <button :disabled="this.$apollo.loading" class="btn btn-primary" @click="this.modalFilterShow()">
              Modifier
            </button>
          </div>
        </div>
      </div>
      <div class="card my-4">
        <div class="card-body">
          <div class="d-flex justify-content-between align-items-center">
            <h5 class="card-title">Paramètres d'affichage des tâches</h5>
            <button :disabled="this.$apollo.loading" class="btn btn-primary" @click="this.modalDisplayFiltersShow()">
              Modifier
            </button>
          </div>
        </div>
      </div>
      <div class="card my-4">
        <div class="card-body">
          <div class="d-flex justify-content-between align-items-center">
            <h5 class="card-title">
              Paramètres de tri des tâches<span class="ms-2 badge badge-primary me-1">{{
                this.getTaskSortOrder.length
              }}</span>
            </h5>
            <button
              :disabled="this.$apollo.loading"
              class="btn btn-primary"
              @click="modals.showTaskSortSettings = true"
            >
              Modifier
            </button>
          </div>
        </div>
      </div>
    </div>
    <TaskSortSettingsModal
      v-if="this.modals.showTaskSortSettings"
      :taskSortOrder="this.getTaskSortOrder"
      @close="this.modals.showTaskSortSettings = false"
      @save="save"
    />
    <KalmModal
      v-if="this.modals.displayFilters.show"
      modalTitle="Affichage des tâches"
      @close="this.modals.displayFilters.show = false"
    >
      <template v-slot:body>
        <!-- Toggle for modals.sort.showComments -->
        <div class="form-check form-switch my-8">
          <input
            class="form-check-input"
            type="checkbox"
            id="showComments"
            v-model="this.modals.displayFilters.showComments"
          />
          <label class="form-check-label" for="showComments">Afficher les commentaires</label>
        </div>
        <!-- Toggle for modals.sort.showFiles -->
        <div class="form-check form-switch mb-8">
          <input
            class="form-check-input"
            type="checkbox"
            id="showFiles"
            v-model="this.modals.displayFilters.showFiles"
          />
          <label class="form-check-label" for="showFiles">Afficher les fichiers</label>
        </div>
      </template>
      <template v-slot:footer>
        <button
          class="btn btn-secondary"
          data-bs-dismiss="modal"
          type="button"
          @click="this.modals.displayFilters.show = false"
        >
          Annuler
        </button>
        <button
          class="btn btn-primary"
          type="button"
          @click="
            this.modals.displayFilters.show = false;
            this.save();
          "
        >
          Enregistrer
        </button>
      </template>
    </KalmModal>
    <KalmModal v-if="this.modals.filter.show" modalTitle="Filtrer les tâches" @close="this.modals.filter.show = false">
      <template v-slot:body>
        <div>
          <StatusMultiSelect v-model="this.modals.filter.includedStatuses" :statuses="this.statuses" />
        </div>
      </template>
      <template v-slot:footer>
        <button
          class="btn btn-secondary"
          data-bs-dismiss="modal"
          type="button"
          @click="this.modals.filter.show = false"
        >
          Annuler
        </button>
        <button
          class="btn btn-primary"
          type="button"
          @click="
            this.modals.filter.show = false;
            this.save();
          "
        >
          Enregistrer
        </button>
      </template>
    </KalmModal>
  </div>
</template>

<script>
import KalmModal from "@/views/_core/components/KalmModal.vue";
import ProjectBreadcrumbComponent from "@/views/project/ProjectBreadcrumbComponent";
import { ALL_STATUSES_QUERY } from "@/views/status/data/status_graphql";
import StatusMultiSelect from "@/views/status/presentation/status-select/StatusMultiSelect.vue";
import { GET_PROJECT_SETTINGS, UPDATE_PROJECT_SETTINGS } from "../../data/project-settings_graphql";
import TaskSortSettingsModal from "./components/TaskSortSettingsModal.vue";
export default {
  components: {
    StatusMultiSelect,
    KalmModal,
    ProjectBreadcrumbComponent,
    TaskSortSettingsModal,
  },
  async mounted() {
    this.projectId = this.$route.params.id;
  },
  data() {
    return {
      projectSettingsByProject: {
        includedStatuses: [],
        taskSortOrder: "",
      },
      statuses: [],
      projectId: undefined,
      modals: {
        showTaskSortSettings: false,
        filter: {
          show: false,
          includedStatuses: [],
        },
        displayFilters: {
          show: false,
          showComments: false,
          showFiles: false,
        },
      },
    };
  },
  watch: {
    $route(to) {
      this.projectId = to.params.id;
    },
  },
  apollo: {
    statuses: {
      query: ALL_STATUSES_QUERY,
    },
    projectSettingsByProject: {
      query: GET_PROJECT_SETTINGS,
      variables() {
        return {
          projectId: this.projectId,
        };
      },
      fetchPolicy: "cache-and-network",
      skip() {
        return !this.projectId;
      },
      result({ data }) {
        if (data && data.projectSettingsByProject) {
          this.modals.displayFilters.showComments = data.projectSettingsByProject.showComments;
          this.modals.displayFilters.showFiles = data.projectSettingsByProject.showFiles;
          this.modals.filter.includedStatuses = this.projectSettingsByProject.includedStatuses.map(
            (status) => status.id
          );
        }
      },
    },
  },
  computed: {
    getTaskSortOrder() {
      if (this.projectSettingsByProject?.taskSortOrder) {
        return JSON.parse(this.projectSettingsByProject?.taskSortOrder);
      } else {
        return [];
      }
    },
  },
  methods: {
    modalFilterShow() {
      this.modals.filter.show = true;
    },
    modalDisplayFiltersShow() {
      this.modals.displayFilters.show = true;
    },
    async save(data) {
      this.modals.showTaskSortSettings = false;
      let sort = [];
      try {
        sort = Object.values(data).filter((s) => s);
      } catch (e) {
        sort = [];
      }
      await this.$apollo.mutate({
        mutation: UPDATE_PROJECT_SETTINGS,
        variables: {
          projectId: this.projectId,
          taskSortOrder: sort,
          includedStatuses: this.modals.filter.includedStatuses,
          showComments: this.modals.displayFilters.showComments,
          showFiles: this.modals.displayFilters.showFiles,
        },
      });
    },
  },
};
</script>
