import { findChildren, mergeAttributes, Node } from "@tiptap/core";

import Document from "@tiptap/extension-document";
import { lotContent, lotHeading } from "./LotContentNodes";
import { VueNodeViewRenderer } from "@tiptap/vue-3";
import LotComponent from "./LotComponent";

import { Extension } from "@tiptap/core";

import { Plugin } from "prosemirror-state";
import { dropPoint } from "prosemirror-transform";

import tableOfContentsNode from "@/views/project/description/CustomNodes/TableOfContents/tableOfContentsNode";
import pageBreakNode from "@/views/project/description/CustomNodes/PageBreak/pageBreakNode";

export function dropCursor(options = {}) {
  return new Plugin({
    view(editorView) {
      return new DropCursorView(editorView, options);
    },
  });
}

class DropCursorView {
  cursorPos = null;
  element = null;
  timeout = -1;

  constructor(editorView, options) {
    this.editorView = editorView;
    this.width = options.width || 1;
    this.color = options.color || "black";
    this.class = options.class;

    this.handlers = ["dragover", "dragend", "drop", "dragleave"].map((name) => {
      let handler = (e) => {
        this[name](e);
      };
      editorView.dom.addEventListener(name, handler);
      return { name, handler };
    });
  }

  destroy() {
    this.handlers.forEach(({ name, handler }) => this.editorView.dom.removeEventListener(name, handler));
  }

  update(editorView, prevState) {
    if (this.cursorPos != null && prevState.doc != editorView.state.doc) {
      if (this.cursorPos > editorView.state.doc.content.size) this.setCursor(null);
      else this.updateOverlay();
    }
  }

  setCursor(pos) {
    console.log("setcursor", pos);
    if (pos == this.cursorPos) return;

    this.cursorPos = pos;
    if (pos == null) {
      this.element.parentNode.removeChild(this.element);
      this.element = null;
    } else {
      this.updateOverlay();
    }
  }

  updateOverlay() {
    console.log("updateOverlay");
    let $pos = this.editorView.state.doc.resolve(this.cursorPos);
    let isBlock = !$pos.parent.inlineContent,
      rect;
    if (isBlock) {
      let before = $pos.nodeBefore,
        after = $pos.nodeAfter;
      if (before || after) {
        let node = this.editorView.nodeDOM(this.cursorPos - (before ? before.nodeSize : 0));
        if (node) {
          let nodeRect = node.getBoundingClientRect();
          let top = before ? nodeRect.bottom : nodeRect.top;
          if (before && after) top = (top + this.editorView.nodeDOM(this.cursorPos).getBoundingClientRect().top) / 2;
          rect = {
            left: nodeRect.left,
            right: nodeRect.right,
            top: top - this.width / 2,
            bottom: top + this.width / 2,
          };
        }
      }
    }
    if (!rect) {
      let coords = this.editorView.coordsAtPos(this.cursorPos);
      rect = {
        left: coords.left - this.width / 2,
        right: coords.left + this.width / 2,
        top: coords.top,
        bottom: coords.bottom,
      };
    }

    let parent = this.editorView.dom.offsetParent;
    if (!this.element) {
      this.element = parent.appendChild(document.createElement("div"));
      if (this.class) this.element.className = this.class;
      this.element.style.cssText =
        "position: absolute; z-index: 50; pointer-events: none; background-color: " + this.color;
    }
    this.element.classList.toggle("prosemirror-dropcursor-block", isBlock);
    this.element.classList.toggle("prosemirror-dropcursor-inline", !isBlock);
    let parentLeft, parentTop;
    if (!parent || (parent == document.body && getComputedStyle(parent).position == "static")) {
      parentLeft = -pageXOffset;
      parentTop = -pageYOffset;
    } else {
      let rect = parent.getBoundingClientRect();
      parentLeft = rect.left - parent.scrollLeft;
      parentTop = rect.top - parent.scrollTop;
    }
    this.element.style.left = rect.left - parentLeft + "px";
    this.element.style.top = rect.top - parentTop + "px";
    this.element.style.width = rect.right - rect.left + "px";
    this.element.style.height = rect.bottom - rect.top + "px";
  }

  scheduleRemoval(timeout) {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => this.setCursor(null), timeout);
  }

  dragover(event) {
    if (!this.editorView.editable) return;

    let pos = this.editorView.posAtCoords({
      left: event.clientX,
      top: event.clientY,
    });

    let node = pos && pos.inside >= 0 && this.editorView.state.doc.nodeAt(pos.inside);
    if (!node) {
      this.setCursor(null);
      return;
    }

    let disabled = node.type.name === "lotHeading" || node.type.name === "docLot";

    if (pos && !disabled) {
      let target = pos.pos;
      if (this.editorView.dragging && this.editorView.dragging.slice) {
        let newDropPoint = dropPoint(this.editorView.state.doc, target, this.editorView.dragging.slice);
        if (newDropPoint != null) target = newDropPoint;
        if (newDropPoint) {
          let targetNode = this.editorView.state.doc.nodeAt(newDropPoint);
          if (targetNode && (targetNode.type.name === "lotHeading" || targetNode.name === "docLot")) {
            target = null;
          }
        } else {
          return;
        }
      }

      this.setCursor(target);
      this.scheduleRemoval(5000);
    } else {
      this.setCursor(null);
    }
  }

  dragend() {
    this.scheduleRemoval(20);
  }

  drop() {
    this.scheduleRemoval(20);
  }

  dragleave(event) {
    if (event.target == this.editorView.dom || !this.editorView.dom.contains(event.relatedTarget)) this.setCursor(null);
  }
}

export const customDropCursor = Extension.create({
  name: "customDropCursor",

  addOptions() {
    return {
      color: "currentColor",
      width: 1,
      class: undefined,
    };
  },

  addProseMirrorPlugins() {
    return [dropCursor(this.options)];
  },
});

export const docLot = Node.create({
  //TODO add lot heading as node property and render node
  name: "docLot",
  priority: 10001,
  group: "docContentGroup",
  extensions: [lotHeading, lotContent],

  content: "lotHeading* lotContent",

  addProseMirrorPlugins() {
    return [
      new Plugin({
        name: "preventDeleteLot",
        filterTransaction(transaction, state) {
          let result = true; // true for keep, false for stop transaction
          const replaceSteps = [];
          transaction.steps.forEach((step, index) => {
            if (step.jsonID === "replace" || step.jsonID === "replaceAround") {
              replaceSteps.push(index);
            }
          });

          replaceSteps.forEach((index) => {
            const map = transaction.mapping.maps[index];
            const oldStart = map.ranges[0];
            const oldEnd = map.ranges[0] + map.ranges[1];
            state.doc.nodesBetween(oldStart, oldEnd, (node) => {
              if (node.type.name === "lotHeading") {
                result = false;
              }
            });
          });
          return result;
        },
      }),
    ];
  },

  addAttributes() {
    return {
      id: {
        type: String,
        default: "",
      },
      expanded: {
        type: Boolean,
        default: false,
      },
      indent: {
        type: Number,
        default: 0,
      },
      hideInPrint: {
        type: Boolean,
        default: true,
      },
      calculatedSum: {
        type: Number,
        default: 0,
      },
      fullName: {
        type: String,
        default: "",
      },
    };
  },

  addGlobalAttributes() {
    return [
      {
        types: ["docLot"],
        attributes: {
          id: {
            default: null,
          },
        },
      },
    ];
  },

  addCommands() {
    return {
      showLotsInPrint:
        (lots, lotsToShow) =>
        ({ tr, chain }) => {
          return chain()
            .forEach(lots, (lotId) => {
              const hideInPrint = !lotsToShow.includes(lotId);
              const item = findChildren(tr.doc, (node) => {
                return node.type.name === "docLot" && node.attrs.id === lotId;
              })?.[0];

              if (!item) {
                return true;
              } else {
                tr.setNodeMarkup(item.pos, undefined, {
                  ...item.node.attrs,
                  hideInPrint,
                });
                return tr;
              }
            })
            .run();
        },
    };
  },

  parseHTML() {
    return [
      {
        tag: "lot",
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ["lot", mergeAttributes(HTMLAttributes), 0];
  },

  addNodeView() {
    return VueNodeViewRenderer(LotComponent);
  },
});

export const customDocument = Document.extend({
  name: "docType",
  content: "(lotParagraph|lotText|lotOuvrage|heading|tableOfContents|pageBreak)+ (docLot)*",
  extensions: [docLot, tableOfContentsNode, pageBreakNode],
});
