<template>
  <div class="row g-3 print-hidden">
    <!-- REPORTS LIST -->
    <div class="col-12">
      <div class="card card-stretch">
        <!--begin::Header-->
        <div class="card-header border-0 pt-5">
          <h3 class="card-title align-items-start flex-column">
            <span class="card-label fw-bolder text-dark fs-3">Comptes-rendus</span>
          </h3>
          <div class="card-toolbar">
            <ul class="nav nav-pills nav-pills-sm nav-light">
              <li class="nav-item">
                <a
                  class="nav-link btn btn-primary fw-bolder me-2"
                  @click="this.$router.push({ name: 'project.tasks', params: { id: this.$route.params.id } })"
                >
                  Créer un compte-rendu
                </a>
              </li>
            </ul>
          </div>
        </div>
        <!--end::Header-->
        <!--begin::Body-->
        <div class="card-body pt-3 mt-n3">
          <div id="myTabTables2" class="tab-content mt-4">
            <!--begin::Table-->
            <div class="table-responsive">
              <div v-if="this.reports.length">
                <table class="table table-borderless align-middle">
                  <thead>
                    <tr>
                      <th class="px-2 py-0 w-50px"></th>
                      <th class="px-2 py-0 w-100"></th>
                      <th></th>
                      <th class="px-2 py-0 min-w-100px"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="report in this.reports"
                      :key="report.id"
                      class="bg-hover-light"
                      @click="openReport(report)"
                    >
                      <td class="px-0 py-3 rounded-start p-5">
                        <div class="symbol symbol-55px ms-5 mt-1 me-5">
                          <span class="symbol-label bg-light-primary align-middle align-items-end">
                            <i class="bi bi-file-check-fill fs-1 align-middle align-self-center"></i>
                          </span>
                        </div>
                      </td>
                      <td class="px-0">
                        <p class="text-gray-800 fw-bolder fs-6 my-1">
                          <span v-if="report?.reportName">{{ report.reportName }}</span>
                          <span v-else>Compte-rendu de chantier n°{{ report.reportNumber }}</span>
                        </p>
                        <p v-if="report.reportDate" class="text-muted">{{ report.reportDate }}</p>
                      </td>
                      <td></td>
                      <td class="px-0 rounded-end">
                        <DuplicateButton @click="duplicateReport(report)" />
                        <DeleteButton @click="openDeleteConfirmModal(report)" />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div v-else>
                <div
                  v-if="this.$apollo.queries.reports.loading"
                  class="d-flex flex-column text-center align-items-center justify-content-center"
                >
                  <div class="spinner-border text-primary my-5" role="status"></div>
                </div>
                <p v-else class="text-center text-muted my-8">
                  <span class="bi bi-folder me-2"></span>
                  Aucun compte-rendu. Cliquez sur créer un compte-rendu pour en ajouter un
                  <span class="ms-1 bi bi-arrow-up-right"></span>
                </p>
              </div>
            </div>
          </div>
          <!--end::Table-->
        </div>
        <!--end::Body-->
      </div>
    </div>
    <ReportForm
      v-if="showReportFormModal"
      :initial-project-id="projectId"
      :report="selectedReport"
      @close="closeReportFormModal"
    />
    <DeleteReportConfirmModal
      v-if="showDeleteConfirmModal"
      :projectId="projectId"
      :reportId="selectedReport.id"
      @close="closeDeleteConfirmModal"
    />
  </div>
</template>

<script>
import DeleteButton from "@/views/_core/components/DeleteButton.vue";
import DuplicateButton from "@/views/_core/components/DuplicateButton.vue";
import { GET_REPORTS } from "../../data/reports_graphql";
import ReportForm from "../report-form/ReportForm.vue";
import DeleteReportConfirmModal from "./components/DeleteReportConfirmModal.vue";

export default {
  name: "ReportsList",
  components: {
    ReportForm,
    DeleteReportConfirmModal,
    DeleteButton,
    DuplicateButton,
  },
  props: {
    projectId: String,
  },
  apollo: {
    reports: {
      query: GET_REPORTS,
      fetchPolicy: "cache-and-network",
      variables() {
        return {
          projectId: this.projectId,
        };
      },
      skip() {
        return !this.projectId;
      },
    },
  },
  data() {
    return {
      reports: [],
      showDeleteConfirmModal: false,
      showReportFormModal: false,
      selectedReport: undefined,
    };
  },
  methods: {
    openReport(report) {
      if (report.file?.url) {
        window.open(report.file.url);
      }
    },
    duplicateReport(report) {
      this.selectedReport = report;
      this.showReportFormModal = true;
    },
    closeReportFormModal() {
      this.showReportFormModal = false;
    },
    closeDeleteConfirmModal() {
      this.showDeleteConfirmModal = false;
    },
    openDeleteConfirmModal(report) {
      this.selectedReport = report;
      this.showDeleteConfirmModal = true;
    },
  },
};
</script>
