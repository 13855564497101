<template>
  <div>
    <div v-if="!this.isLoading" class="d-row text-center justify-content-center g-10">
      <div
        v-for="file in task.files"
        :key="file.id"
        class="position-relative d-inline-block m-5"
        style="height: 150px; max-width: 150px"
      >
        <!-- Display an image if the file is an image -->
        <img
          v-if="isImage(file)"
          :src="buildFullUrl(file.url)"
          alt="Fichier non décrit inclue sur la tâche pour le CR"
          class="rounded shadow shadow-xs h-100 w-100"
        />

        <!-- Display a file icon if the file is not an image -->
        <div
          v-else
          class="d-flex flex-column align-items-center justify-content-center rounded shadow shadow-xs h-100 w-100 text-center text-muted"
        >
          <i class="bi bi-file-earmark-text fs-1"></i>
          <p :title="file.name" class="text-truncate mt-2 mb-0 w-75">{{ file.name }}</p>
        </div>

        <!-- Delete button positioned at the top-right corner of each card -->
        <button
          class="btn btn-icon btn-sm btn-primary position-absolute top-0 end-0 translate-middle-y me-n4"
          @click="this.removeFileFromTask(file.id)"
        >
          <span class="bi bi-x"></span>
        </button>

        <!-- Open file in new tab -->
        <button
          class="btn btn-icon btn-sm btn-primary position-absolute top-0 start-0 translate-middle-y ms-n4"
          @click="this.downloadFileFromTask(file.url)"
        >
          <span class="bi bi-download"></span>
        </button>
      </div>
    </div>
    <div v-else class="mt-4 mb-10 absolute">
      <div class="spinner-border"></div>
    </div>
  </div>
</template>
<script>
import { UNASSIGN_TASK } from "@/views/documents/data/files_graphql";
import { GET_TASK_BY_ID } from "@/views/tasks/data/tasks_graphql";

export default {
  name: "FilesForTask",
  apollo: {
    task: {
      query: GET_TASK_BY_ID,
      variables() {
        return {
          taskId: this.taskId,
        };
      },
      skip() {
        return !this.taskId;
      },
    },
  },
  data() {
    return {
      task: {
        files: [],
      },
      isLoading: false,
    };
  },
  props: {
    taskId: {
      type: String,
      required: true,
    },
  },
  components: {},
  methods: {
    isImage(file) {
      return (
        file.url.toLowerCase().endsWith(".png") ||
        file.url.toLowerCase().endsWith(".jpg") ||
        file.url.toLowerCase().endsWith(".jpeg") ||
        file.url.toLowerCase().endsWith(".gif")
      );
    },
    downloadFileFromTask(fileUrl) {
      window.open(this.buildFullUrl(fileUrl), "_blank");
    },
    removeFileFromTask(fileId) {
      this.$apollo.mutate({
        mutation: UNASSIGN_TASK,
        variables: {
          fileId: fileId,
          taskToRemoveId: this.taskId,
        },
        context: {
          hasUpload: true,
        },
        refetchQueries: [
          {
            query: GET_TASK_BY_ID,
            variables: {
              taskId: this.taskId,
            },
            skip() {
              return !this.taskId;
            },
          },
        ],
      });
    },
    buildFullUrl(url) {
      if (url.startsWith("http")) {
        return url;
      }
      return process.env.VUE_APP_URL_BACK + url;
    },
  },
  computed: {
    projectId() {
      return this.$store.getters.currentProjectFocus;
    },
  },
};
</script>
