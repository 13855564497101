<template>
  <div>
    <div v-if="comment">
      <input v-model="updatedAt" type="datetime-local" class="form-control mb-6" id="content" />
      <div class="mb-6 w-100">
        <label class="col-form-label" for="color">Commentaire mise en avant (couleur) :</label>
        <div class="w-100 d-flex">
          <input id="color_default" v-model="this.color" :value="null" class="btn-check" type="radio" />
          <label class="flex-grow-1 btn btn-light rounded-end-0" for="color_default">Par défaut</label>

          <input
            id="color_persoColor1"
            v-model="this.color"
            :value="this.currentUser?.org?.persoColor1"
            class="btn-check"
            type="radio"
          />
          <label class="btn btn-icon btn-light rounded-0" for="color_persoColor1"
            ><span
              :style="'background-color: ' + this.currentUser?.org?.persoColor1"
              class="rounded-circle h-20px w-20px text-white"
              >1</span
            ></label
          >

          <input
            id="color_persoColor2"
            v-model="this.color"
            :value="this.currentUser?.org?.persoColor2"
            class="btn-check"
            type="radio"
          />
          <label class="btn btn-icon btn-light rounded-0" for="color_persoColor2"
            ><span
              :style="'background-color: ' + this.currentUser?.org?.persoColor2"
              class="rounded-circle h-20px w-20px text-white"
              >2</span
            ></label
          >

          <input
            id="color_persoColor3"
            v-model="this.color"
            :value="this.currentUser?.org?.persoColor3"
            class="btn-check"
            type="radio"
          />
          <label class="btn btn-icon btn-light rounded-0" for="color_persoColor3"
            ><span
              :style="'background-color: ' + this.currentUser?.org?.persoColor3"
              class="rounded-circle h-20px w-20px text-white"
              >3</span
            ></label
          >

          <input
            id="color_persoColor4"
            v-model="this.color"
            :value="this.currentUser?.org?.persoColor4"
            class="btn-check"
            type="radio"
          />
          <label class="btn btn-icon btn-light rounded-0" for="color_persoColor4"
            ><span
              :style="'background-color: ' + this.currentUser?.org?.persoColor4"
              class="rounded-circle h-20px w-20px text-white"
              >4</span
            ></label
          >

          <input
            id="color_persoColor5"
            v-model="this.color"
            :value="this.currentUser?.org?.persoColor5"
            class="btn-check"
            type="radio"
          />
          <label class="btn btn-icon btn-light rounded-0" for="color_persoColor5"
            ><span
              :style="'background-color: ' + this.currentUser?.org?.persoColor5"
              class="rounded-circle h-20px w-20px text-white"
              >5</span
            ></label
          >

          <input
            id="color_persoColor6"
            v-model="this.color"
            :value="this.currentUser?.org?.persoColor6"
            class="btn-check"
            type="radio"
          />
          <label class="btn btn-icon btn-light rounded-start-0" for="color_persoColor6"
            ><span
              :style="'background-color: ' + this.currentUser?.org?.persoColor6"
              class="rounded-circle h-20px w-20px text-white"
              >6</span
            ></label
          >
        </div>
      </div>
    </div>
    <label class="form-label" for="content">Commentaire</label>
    <textarea v-model="content" type="text" rows="3" class="form-control" id="content" />
    <div class="mt-2 pt-1">
      <button v-if="!isSaving" @click="save" :disabled="!canSave()" type="button" class="btn btn-primary btn-sm">
        Commenter
      </button>

      <LoadingButton v-if="isSaving"></LoadingButton>
    </div>
  </div>
</template>

<script>
import { CURRENT_USER_QUERY } from "@/graphql/graphql";
import LoadingButton from "@/views/_core/components/LoadingButton.vue";
import dayjs from "dayjs";
import { CREATE_COMMENT, GET_COMMENTS, UPDATE_COMMENT } from "../../data/comments_graphql";

export default {
  name: "CommentForm",
  props: {
    comment: Object,
    taskId: {
      type: String,
      required: true,
    },
  },
  data() {
    const now = new Date().toISOString().slice(0, 16);
    return {
      updatedAt: now,
      content: "",
      color: "",
      isSaving: false,
    };
  },
  created() {
    this.initializeData();
  },
  apollo: {
    currentUser: CURRENT_USER_QUERY,
  },
  methods: {
    initializeData() {
      if (this.comment) {
        this.updatedAt = dayjs(this.comment.updatedAt).format("YYYY-MM-DDTHH:mm");
        this.content = this.comment.content;
        this.color = this.comment.color;
      }
    },
    canSave() {
      return this.content?.length;
    },
    async save() {
      this.isSaving = true;
      if (this.comment) {
        try {
          await this.$apollo.mutate({
            mutation: UPDATE_COMMENT,
            variables: {
              id: this.comment.id,
              content: this.content,
              updatedAt: this.updatedAt,
              color: this.color,
            },
            refetchQueries: [
              {
                query: GET_COMMENTS,
                variables: {
                  taskId: this.taskId,
                },
              },
            ],
          });
        } catch (error) {
          console.error("Update comment error:", error);
        }
      } else {
        try {
          await this.$apollo.mutate({
            mutation: CREATE_COMMENT,
            variables: {
              content: this.content,
              taskId: this.taskId,
            },
            refetchQueries: [
              {
                query: GET_COMMENTS,
                variables: {
                  taskId: this.taskId,
                },
              },
            ],
          });
          this.content = "";
        } catch (error) {
          console.error("Create comment error:", error);
        }
      }
      this.isSaving = false;
      this.$emit("save");
    },
  },
  components: { LoadingButton },
};
</script>
