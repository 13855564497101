<template>
  <div class="text-center">
    <img :src="image" alt="No Todo" class="img-fluid w-25" />
    <h3 class="card-title my-8">
      <span class="card-label fw-bolder text-dark fs-3">{{ message }}</span>
    </h3>
    <div class="row g-2 justify-content-center">
      <a class="col-sm-8 col-12 btn btn-primary fw-bolder" @click="emitAdd">{{ buttonMessage }}</a>
      <a class="col-sm-8 col-12 btn btn-secondary fw-bolder ms-2" @click="this.$router.push({ name: 'canevas' })">
        Ajouter depuis un template
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "TodoEmptyState",
  props: {
    message: {
      type: String,
      default: "Cette todo est vide",
    },
    buttonMessage: {
      type: String,
      default: "Ajouter un élément",
    },
    image: {
      type: String,
      default: "/assets/media/svg/empty-states/empty-todo.svg",
    },
  },
  methods: {
    emitAdd() {
      this.$emit("add");
    },
  },
};
</script>
