import { VueNodeViewRenderer } from "@tiptap/vue-3";
import { mergeAttributes, Node } from "@tiptap/core";
import TableOfContentsComponent from "@/views/project/description/CustomNodes/TableOfContents/TableOfContentsComponent.vue";

export default Node.create({
  name: "tableOfContents",
  draggable: true,

  addAttributes() {
    return {
      ...this.parent?.(),
    };
  },

  renderHTML({ HTMLAttributes }) {
    return ["table-of-contents", mergeAttributes(HTMLAttributes, { "data-type": "draggable-item" })];
  },

  parseHTML() {
    return [
      {
        tag: "table-of-contents",
      },
    ];
  },

  addNodeView() {
    return VueNodeViewRenderer(TableOfContentsComponent);
  },
});
