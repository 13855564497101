<template>
  <main :class="isModalActive ? 'blur-content' : ''">
    <section class="row">
      <IconButton iconName="arrow-left" @click="back"></IconButton>
    </section>
    <section class="row g-5">
      <div class="col-lg-6 col-12">
        <div class="card card-stretch">
          <div class="card-header d-flex justify-content-between align-items-center border-bottom py-3">
            <div class="d-flex align-items-center">
              <AvatarLogo :url="preview" height="mh-60px" />
              <div class="mx-3">
                <span class="text-black fw-bold d-block">
                  {{ contact?.company }}
                </span>
                <span class="text-muted fw-bold d-block">
                  {{ contact?.name }}
                </span>
              </div>
            </div>

            <button
              class="btn btn-icon btn-bg-light btn-text-gray-600 btn-active-primary btn-sm btn-active-white"
              @click="this.modals.editContact.show = true"
            >
              <span class="bi bi-pencil-fill"></span>
            </button>
          </div>
          <div class="card-body border-bottom">
            <h2 class="text-black fw-bold d-block">Informations Personnelles</h2>

            <ul class="mt-3 px-0">
              <li v-for="(value, key) in getPersonnalInformations" :key="key" class="d-flex">
                <span class="text-muted fw-bold d-block">{{ renameKey(key) }}:</span>
                <span class="text-black fw-bold d-block mx-3">{{ value }}</span>
              </li>
            </ul>
          </div>
          <div class="card-body">
            <h2 class="text-black fw-bold d-block">Autres Informations</h2>
            <ul class="mt-3 px-0">
              <li v-for="(value, key) in getOthersInformations" :key="key" class="d-flex">
                <span class="text-muted fw-bold d-block">{{ renameKey(key) }}:</span>
                <span class="text-black fw-bold d-block mx-3">{{ value }}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-12">
        <div class="card card-stretch">
          <div class="card-body pt-3 mt-n3">
            <h2 class="text-black fw-bold d-block pt-5">Projets</h2>
            <div id="projectParticipationTable" class="tab-content mt-4">
              <div class="table-responsive">
                <table class="table table-borderless align-middle">
                  <thead>
                    <tr>
                      <th class="p-0 w-50px"></th>
                      <th class="p-0 w-150px"></th>
                      <th class="p-0 min-w-70px"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="participation in contact?.projectParticipations"
                      :key="participation.project.id"
                      class="bg-hover-light"
                    >
                      <td class="ps-3 rounded-start">
                        <i class="bi bi-bricks fs-1 align-middle align-self-center"></i>
                      </td>
                      <td class="px-0 w-100">
                        <span class="text-black fw-bold d-block mt-1">{{ participation.project.name }}</span>
                        <span class="text-muted fw-bold d-block mt-1">{{ participation.role }}</span>
                      </td>
                      <td class="text-end pe-3 rounded-end"></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="card card-stretch">
          <div class="card-body pt-3 mt-3">
            <h2 class="text-black fw-bold d-block pt-5">Devis</h2>
            <div id="projectParticipationTable" class="tab-content mt-4">
              <div class="table-responsive">
                <table class="table table-borderless align-middle">
                  <thead>
                    <tr>
                      <th class="p-0 w-50px"></th>
                      <th class="p-0 w-150px"></th>
                      <th class="p-0 min-w-70px"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="proposal in contact?.proposals" :key="proposal.id" class="bg-hover-light">
                      <td class="ps-3 rounded-start">
                        <i class="bi fs-1 bi-file-text align-middle align-self-center"></i>
                      </td>
                      <td class="px-0">
                        <span class="text-black fw-bold d-block mt-1">{{ proposal.name }}</span>
                        <span class="text-muted fw-bold d-block mt-1">{{ proposal.project.name }}</span>
                      </td>
                      <td class="text-end pe-3 rounded-end"></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="card card-stretch">
          <FileList
            :assignButton="false"
            :callback="callbackOnFile"
            :contactId="this.contact?.id"
            :editButton="false"
            :files="getFiles"
            :query="CONTACT_BY_ID"
            @onDelete="modalConfirmDeleteFileOpen"
            @onShare="onShareFileClick"
          />
        </div>
      </div>
    </section>
  </main>
  <ContactUpdateModal v-if="modals.editContact.show" v-model="current" :callback="callback" @close="closeModal()" />
  <DeleteFileConfirmModal
    v-if="modals.deleteFile.show"
    :callback="callbackOnFile"
    :fileId="fileId"
    @close="modalConfirmDeleteFileClose"
  />
  <!-- SHARE FILE -->
  <LinkCreationModal
    v-if="modals.linkCreationModal.show"
    :file-id="modals.linkCreationModal.file.id"
    @close="modals.linkCreationModal.show = false"
  ></LinkCreationModal>
</template>

<script>
import AvatarLogo from "@/views/_core/components/AvatarLogo.vue";
import ContactUpdateModal from "../components/ContactUpdateModal.vue";
import DeleteFileConfirmModal from "@/views/documents/presentation/file-explorer/components/DeleteFileConfirmModal.vue";
import { CONTACT_BY_ID } from "../../data/contacts_graphql";
import { FILE_UPDATE } from "@/views/documents/data/files_graphql";
import FileList from "@/views/documents/presentation/file-explorer/components/FileList.vue";
import IconButton from "@/views/_core/components/IconButton.vue";
import LinkCreationModal from "@/views/links/presentation/link-creation-modal/LinkCreationModal.vue";

export default {
  name: "ContactDetailsPage",
  components: {
    LinkCreationModal,
    AvatarLogo,
    ContactUpdateModal,
    DeleteFileConfirmModal,
    FileList,
    IconButton,
  },
  data() {
    return {
      modals: {
        editContact: {
          show: false,
        },
        deleteFile: {
          show: false,
        },
        linkCreationModal: {
          show: false,
          file: {
            id: "",
          },
        },
      },
      contact: null,
      fileId: null,
      current: {
        id: "",
        type: "",
        company: "",
        name: "",
        role: "",
        email: "",
        phone: "",
        phone2: "",
        infoWebsite: "",
        infoCorps: "",
        infoSiret: "",
        infoRole: "",
        infoInsurance: "",
        infoAddress: "",
        infoTva: "",
        infoComment: "",
        infoLine1: "",
        infoLine2: "",
        logoUrl: "",
      },
    };
  },
  apollo: {
    contact: {
      query: CONTACT_BY_ID,
      variables() {
        return {
          contactId: this.$route.params.id,
        };
      },
      skip() {
        return !this.$route.params.id;
      },
      result({ data }) {
        Object.keys(data.contact).forEach((key) => {
          this.current[key] = data.contact[key];
        });
      },
      fetchPolicy: "cache-and-network",
    },
  },
  methods: {
    onShareFileClick(file) {
      this.modals.linkCreationModal.file = {
        id: file.id,
      };
      this.modals.linkCreationModal.show = true;
    },
    keepKeys(object, keys) {
      return (
        Object.entries(object)
          // eslint-disable-next-line no-unused-vars
          .filter(([key, _]) => {
            return keys.includes(key);
          })
          .reduce((obj, key) => {
            obj[key[0]] = key[1];
            return obj;
          }, {})
      );
    },
    back() {
      this.$router.push("/contacts");
    },
    callback() {
      this.$apollo.queries.contact.refetch();
    },
    closeModal() {
      this.modals.editContact.show = false;
    },
    editContactFile(file) {
      this.$apollo.mutate({
        mutation: FILE_UPDATE,
        variables: {
          file: {
            id: file.id,
            name: file.name,
            description: file.description,
          },
        },
      });
    },
    callbackOnFile() {
      this.$apollo.queries.contact.refetch();
    },
    modalConfirmDeleteFileOpen(file) {
      this.fileId = file.id;
      this.modals.deleteFile.show = true;
    },
    modalConfirmDeleteFileClose() {
      this.fileId = null;
      this.modals.deleteFile.show = false;
    },
    renameKey(key) {
      const keys = {
        infoAddress: "Adresse",
        infoComment: "Commentaire",
        infoCorps: "Corps",
        infoInsurance: "Assurance",
        infoLine1: "Ligne 1",
        infoLine2: "Ligne 2",
        infoSiret: "Siret",
        infoRole: "Role",
        infoTva: "Tva",
        infoWebsite: "Site web",
        name: "Nom",
        email: "Email",
        company: "Entreprise",
        phone: "Téléphone",
        phone2: "Téléphone 2",
        type: "Type",
      };

      return keys[key] || key;
    },
  },
  computed: {
    getPersonnalInformations() {
      if (!this.contact) return {};

      return this.keepKeys(this.contact, ["name", "email", "company", "phone", "phone2", "type", "infoAddress"]);
    },
    getOthersInformations() {
      if (!this.contact) return {};

      return this.keepKeys(this.contact, [
        "infoComment",
        "infoCorps",
        "infoInsurance",
        "infoLine1",
        "infoLine2",
        "infoSiret",
        "infoRole",
        "infoTva",
        "infoWebsite",
      ]);
    },
    isModalActive() {
      return this.modals.editContact.show;
    },
    editContact() {
      return { ...this.contact };
    },
    getFiles() {
      if (!this.contact) return [];

      return this.contact.files;
    },
    preview() {
      if (!this.contact?.logoUrl) return null;

      return this.contact.logoUrl;
    },
  },
};
</script>

<style scoped></style>
