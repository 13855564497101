<template>
  <div class="">
    <div class="row">
      <div :class="isModalActive ? 'blur-content' : ''" class="col-12 container p-0">
        <div class="mt-5 mb-1 d-flex print-hidden">
          <div class="d-flex w-100 justify-content-between">
            <div class="d-flex">
              <button class="btn btn-icon btn-secondary" @click="this.$router.push({ name: 'project.description' })">
                <span class="bi bi-arrow-left"></span>
              </button>
              <h3 class="align-middle my-auto ms-3">
                Version {{ this.version.number }}
                <span v-if="this.version.name">— {{ this.version.name }}</span>
                ({{ new Date(this.version.createdAt).toLocaleString("fr-FR") }})
              </h3>
            </div>
            <button class="btn btn-primary" @click="this.modals.restoreVersion.show = true">
              Restaurer cette version
            </button>
          </div>
        </div>
        <div class="editor row mt-3 g-0">
          <div v-if="editor" class="justify-content-center d-flex flex-lg-nowrap flex-wrap mx-auto print-hidden w-100">
            <div
              class="print-hidden editor-toolbar toolbar-top px-7 py-1 d-flex justify-content-between align-items-center w-100 mt-0"
            ></div>
          </div>
          <div
            v-if="editor && this.isEditorScrolledDown"
            class="justify-content-center d-flex flex-lg-nowrap flex-wrap mx-auto print-hidden w-100 px-5 editor-buttons-sticky"
          >
            <div
              class="print-hidden editor-toolbar toolbar-sticky px-7 py-1 d-flex d-flex justify-content-between align-items-center w-100 mt-0"
            >
              <!--              <div class="d-flex">
                &lt;!&ndash; Not shown on >XXL because of permanent table &ndash;&gt;
                <button
                  v-tooltip:bottom="'Table des matières'"
                  :disabled="!editor.can().chain().focus().undo().run()"
                  class="btn btn-color-white mx-1 p-2 d-xxl-none"
                  title="Table des matières"
                  @click="this.menuTableOfContentsToggle()"
                >
                  <span class="text-center align-middle">
                    <i class="bi bi-justify fs-2 p-0"></i>
                  </span>
                </button>
                <div v-tooltip:bottom="'Mode réduit'" class="d-flex" title="Mode réduit">
                  <div class="my-auto pt-1 form-check form-switch form-switch-sm">
                    <input
                      id="descriptionDisplayModeToggle"
                      v-model="isDescriptionDisplayModeCollapsed"
                      class="form-check-input border-white"
                      type="checkbox"
                    />
                  </div>
                </div>
              </div>
              <div class="px-5 text-center">
                <button
                  v-tooltip:bottom="'Annuler'"
                  :disabled="!editor.can().chain().focus().undo().run()"
                  class="btn btn-color-white mx-1 p-2"
                  @click="editor.chain().focus().undo().run()"
                >
                  <span class="text-center align-middle">
                    <i class="bi bi-arrow-counterclockwise fs-2 p-0"></i>
                  </span>
                </button>
                <button
                  v-tooltip:bottom="'Refaire'"
                  :disabled="!editor.can().chain().focus().redo().run()"
                  class="btn btn-color-white mx-1 p-2"
                  @click="editor.chain().focus().redo().run()"
                >
                  <span class="text-center align-middle">
                    <i class="bi bi-arrow-clockwise fs-2 p-0"></i>
                  </span>
                </button>
                <span class="text-muted mx-2 my-auto">|</span>
                <button
                  v-tooltip:bottom="'Imprimer ou télécharger .pdf'"
                  class="btn btn-color-white mx-1 p-2"
                  @click="modalPrintShow()"
                >
                  <span class="text-center align-middle">
                    <i class="bi bi-printer fs-2 p-0"></i>
                  </span>
                </button>
                <button
                  v-tooltip:bottom="'Importer des ouvrages'"
                  :disabled="!editor.isActive('lotParagraph')"
                  class="btn btn-color-white mx-1 p-2"
                  @click="modalImportShow()"
                >
                  <span class="text-center align-middle">
                    <i class="bi bi-cloud-download fs-3 p-0"></i>
                  </span>
                </button>
                <span class="text-muted mx-2 my-auto">|</span>
                <select
                  v-model="this.menuTypeStyle"
                  class="d-inline form-select align-middle mx-1 px-2 py-1"
                  style="width: 140px"
                  @change="this.setTextHeadingLevel($event)"
                >
                  <option value="paragraph">Texte normal</option>
                  <option value="heading1">Titre</option>
                  <option value="heading2">Titre 1</option>
                  <option value="heading3">Titre 2</option>
                  <option value="heading4">Titre 3</option>
                  <option value="heading5">Titre 4</option>
                  <option value="heading6">Titre 5</option>
                </select>
                &lt;!&ndash;            <button
                                          v-tooltip:bottom="'Titre'"
                                          :disabled="!editor.can().chain().focus().toggleHeading({ level: 1 }).run()"
                                          class="btn btn-color-white mx-1 p-2"
                                          @click="editor.chain().focus().toggleHeading({ level: 1 }).run()"
                                        >
                                          <span class="text-center align-middle"> T1 </span>
                                        </button>
                                        <button
                                          v-tooltip:bottom="'Titre'"
                                          :disabled="!editor.can().chain().focus().toggleHeading({ level: 2 }).run()"
                                          class="btn btn-color-white mx-1 p-2"
                                          @click="editor.chain().focus().toggleHeading({ level: 2 }).run()"
                                        >
                                          <span class="text-center align-middle"> T2 </span>
                                        </button>
                                        <button
                                          v-tooltip:bottom="'Titre'"
                                          :disabled="!editor.can().chain().focus().toggleHeading({ level: 4 }).run()"
                                          class="btn btn-color-white mx-1 p-2"
                                          @click="editor.chain().focus().toggleHeading({ level: 4 }).run()"
                                        >
                                          <span class="text-center align-middle">
                                            <i class="bi bi-type-h1 fs-2 p-0"></i>
                                          </span>
                                        </button>
                                        <button
                                          v-tooltip:bottom="'Paragraphe'"
                                          :disabled="!editor.can().chain().focus().toggleNode('paragraph', 'text').run()"
                                          class="btn btn-color-white mx-1 p-2"
                                          @click="editor.chain().focus().toggleNode('paragraph', 'text').run()"
                                        >
                                          <span class="text-center align-middle">
                                            <i class="bi bi-justify-left fs-2 p-0"></i>
                                          </span>
                                        </button>
                                        <span class="text-muted mx-2 my-auto">|</span>&ndash;&gt;
                <button
                  v-tooltip:bottom="'Gras'"
                  :class="{ 'is-active': editor.isActive('bold') }"
                  :disabled="!editor.can().chain().focus().toggleBold().run()"
                  class="btn btn-color-white mx-1 p-2"
                  @click="editor.chain().focus().toggleBold().run()"
                >
                  <span class="text-center align-middle">
                    <i class="bi bi-type-bold fs-2 p-0"></i>
                  </span>
                </button>
                <button
                  v-tooltip:bottom="'Italique'"
                  :class="{ 'is-active': editor.isActive('italic') }"
                  :disabled="!editor.can().chain().focus().toggleItalic().run()"
                  class="btn btn-color-white mx-1 p-2"
                  @click="editor.chain().focus().toggleItalic().run()"
                >
                  <span class="text-center align-middle">
                    <i class="bi bi-type-italic fs-2 p-0"></i>
                  </span>
                </button>
                <button
                  v-tooltip:bottom="'Sous-ligner'"
                  :class="{ 'is-active': editor.isActive('underline') }"
                  :disabled="!editor.can().chain().focus().toggleUnderline().run()"
                  class="btn btn-color-white mx-1 p-2"
                  @click="editor.chain().focus().toggleUnderline().run()"
                >
                  <span class="text-center align-middle">
                    <i class="bi bi-type-underline fs-2 p-0"></i>
                  </span>
                </button>
                <button
                  v-tooltip:bottom="'Barrer'"
                  :class="{ 'is-active': editor.isActive('strike') }"
                  :disabled="!editor.can().chain().focus().toggleStrike().run()"
                  class="btn btn-color-white mx-1 p-2"
                  @click="editor.chain().focus().toggleStrike().run()"
                >
                  <span class="text-center align-middle">
                    <i class="bi bi-type-strikethrough fs-2 p-0"></i>
                  </span>
                </button>
                <button class="btn btn-color-white mx-1 p-2" @click="this.menuColorToggle()">
                  <span
                    :style="'border-bottom: 2px solid' + (editor.getAttributes('textStyle').color || '#000000')"
                    class="text-center"
                  >
                    <i class="bi bi-text-alphabet fs-2 p-0 pb-0 mb-0" style="font-style: normal">A</i>
                  </span>
                </button>
                <span class="text-muted mx-2 my-auto">|</span>
                <button
                  v-tooltip:bottom="'Liste à puces'"
                  :class="{ 'is-active': editor.isActive('strike') }"
                  :disabled="!editor.can().chain().focus().toggleStrike().run()"
                  class="btn btn-color-white mx-1 p-2"
                  @click="editor.chain().focus().toggleBulletList().run()"
                >
                  <span class="text-center align-middle">
                    <i class="bi bi-list-ul fs-2 p-0"></i>
                  </span>
                </button>
              </div>
              <div class="">
                <button class="btn btn-color-white mx-1 p-2" title="Enregistrer" @click="this.modalVersionShow()">
                  <span class="bi bi-star-fill fs-2"></span>
                </button>
                <button
                  :disabled="!this.leaveGuard"
                  class="btn btn-color-white mx-1 p-2"
                  title="Enregistrer"
                  @click="this.saveDocument()"
                >
                  <span class="d-none d-md-block text-center align-middle">
                    Enregistrer
                    <span
                      v-if="this.isSaving"
                      class="mt-1 ms-2 spinner-border spinner-border-sm position-absolute"
                    ></span>
                  </span>
                  <span class="d-md-none la la-floppy-o fs-1"></span>
                </button>
              </div>-->
            </div>
            <div
              v-if="this.menuTableOfContents.show"
              class="card shadow w-300px p-3"
              style="position: absolute; top: 60px; left: 10px; height: 500px"
            >
              <i
                class="bi bi-x text-gray-800 text-hover-primary cursor-pointer fs-1"
                style="position: absolute; top: 10px; right: 10px"
                @click="this.menuTableOfContentsToggle()"
              ></i>
              <div class="card-body overflow-x-scroll">
                <div
                  v-for="lot in this.currentProject?.lots"
                  :key="lot.id"
                  :class="'ms-' + (lot.number.match(/\./g) || []).length * 4"
                  class="text-nowrap text-truncate overflow-y-hidden overflow-x-scroll"
                >
                  <a
                    class="link cursor-pointer"
                    @click="
                      this.scrollToLot(`#lot-${lot.id}`);
                      this.menuTableOfContentsToggle();
                    "
                    >{{ lot.fullName }}</a
                  >
                </div>
              </div>
            </div>
            <div v-if="this.menuColor.show" class="card shadow p-3" style="position: absolute; top: 60px">
              <div class="card-body p-1">
                <button
                  :class="{
                    'is-active': editor.isActive('textStyle', {
                      color: this.currentUser.org.persoColor1 || '#000000',
                    }),
                  }"
                  :style="`background-color: ${this.currentUser.org.persoColor1 || `#000000`}; height: 20px; width: 20px;`"
                  class="btn btn-icon rounded-circle m-1"
                  @click="
                    this.editor
                      .chain()
                      .focus()
                      .setColor(this.currentUser.org.persoColor1 || '#000000')
                      .run()
                  "
                ></button>
                <button
                  :class="{
                    'is-active': editor.isActive('textStyle', {
                      color: this.currentUser.org.persoColor2 || '#195D5B',
                    }),
                  }"
                  :style="`background-color: ${this.currentUser.org.persoColor2 || `#195D5B`}; height: 20px; width: 20px;`"
                  class="btn btn-icon rounded-circle m-1"
                  @click="
                    this.editor
                      .chain()
                      .focus()
                      .setColor(this.currentUser.org.persoColor2 || '#195D5B')
                      .run()
                  "
                ></button>
                <button
                  :class="{
                    'is-active': editor.isActive('textStyle', {
                      color: this.currentUser.org.persoColor3 || '#3C78D8',
                    }),
                  }"
                  :style="`background-color: ${this.currentUser.org.persoColor3 || `#3C78D8`}; height: 20px; width: 20px;`"
                  class="btn btn-icon rounded-circle m-1"
                  @click="
                    this.editor
                      .chain()
                      .focus()
                      .setColor(this.currentUser.org.persoColor3 || '#3C78D8')
                      .run()
                  "
                ></button>
                <button
                  :class="{
                    'is-active': editor.isActive('textStyle', {
                      color: this.currentUser.org.persoColor4 || '#A64D79',
                    }),
                  }"
                  :style="`background-color: ${this.currentUser.org.persoColor4 || `#A64D79`}; height: 20px; width: 20px;`"
                  class="btn btn-icon rounded-circle m-1"
                  @click="
                    this.editor
                      .chain()
                      .focus()
                      .setColor(this.currentUser.org.persoColor4 || '#A64D79')
                      .run()
                  "
                ></button>
                <button
                  :class="{
                    'is-active': editor.isActive('textStyle', {
                      color: this.currentUser.org.persoColor5 || '#CC0000',
                    }),
                  }"
                  :style="`background-color: ${this.currentUser.org.persoColor5 || `#CC0000`}; height: 20px; width: 20px;`"
                  class="btn btn-icon rounded-circle m-1"
                  @click="
                    this.editor
                      .chain()
                      .focus()
                      .setColor(this.currentUser.org.persoColor5 || '#CC0000')
                      .run()
                  "
                ></button>
                <button
                  :class="{
                    'is-active': editor.isActive('textStyle', {
                      color: this.currentUser.org.persoColor6 || '#F1C232',
                    }),
                  }"
                  :style="`background-color: ${this.currentUser.org.persoColor6 || `#F1C232`}; height: 20px; width: 20px;`"
                  class="btn btn-icon rounded-circle m-1"
                  @click="
                    this.editor
                      .chain()
                      .focus()
                      .setColor(this.currentUser.org.persoColor6 || '#F1C232')
                      .run()
                  "
                ></button>
                <i
                  class="bi bi-x text-gray-800 text-hover-primary cursor-pointer align-middle fs-1"
                  @click="this.menuColorToggle()"
                ></i>
              </div>
            </div>
            <div
              v-if="this.modalVersion.show"
              class="card shadow w-350px p-3"
              style="position: absolute; top: 60px; right: 10px"
            >
              <i
                class="bi bi-x text-gray-800 text-hover-primary cursor-pointer fs-1"
                style="position: absolute; top: 10px; right: 10px"
                @click="this.modalVersionCancel()"
              ></i>
              <div class="card-body overflow-x-scroll">
                <div v-if="this.versions.length === 0" class="text-center">
                  <p class="text-muted">Aucune version enregistrée sur ce document</p>
                </div>
                <div
                  v-for="version in this.versions"
                  v-else
                  :key="version.id"
                  class="text-nowrap text-truncate overflow-y-hidden overflow-x-scroll"
                >
                  <p class="mb-0 pb-0">
                    <!--                    <button class="btn btn-icon btn-sm btn-outline border-0" disabled>
                      <span class="bi bi-arrow-counterclockwise"></span>
                    </button>-->
                    Version {{ version.number }}
                    <span v-if="version.createdAt"> ({{ new Date(version.createdAt).toLocaleString("fr-FR") }})</span>
                  </p>
                </div>
                <div class="text-center">
                  <button
                    :disabled="this.$apollo.queries.versions.loading"
                    class="btn btn-primary text-center mt-3"
                    @click="this.modalVersionCreate()"
                  >
                    Figer une version<span v-if="this.$apollo.queries.versions.loading" class="spinner-border"></span>
                  </button>
                  <p class="text-muted text-center mt-2">
                    Les versions figées vous seront utiles pour le dossier de consultation des entreprises
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="editor && !this.isEditorScrolledDown"
            class="justify-content-center d-flex flex-lg-nowrap flex-wrap mx-auto print-hidden w-100 px-5 editor-buttons-sticky"
          >
            <div
              v-if="this.menuTableOfContents.show"
              class="card shadow w-300px p-3"
              style="position: absolute; top: 10px; left: 10px; height: 500px"
            >
              <i
                class="bi bi-x text-gray-800 text-hover-primary cursor-pointer fs-1"
                style="position: absolute; top: 10px; right: 10px"
                @click="this.menuTableOfContentsToggle()"
              ></i>
              <div class="card-body overflow-x-scroll">
                <div
                  v-for="lot in this.currentProject?.lots"
                  :key="lot.id"
                  :class="'ms-' + (lot.number.match(/\./g) || []).length * 4"
                  class="text-nowrap text-truncate overflow-y-hidden overflow-x-scroll"
                >
                  <a
                    class="link cursor-pointer"
                    @click="
                      this.scrollToLot(`#lot-${lot.id}`);
                      this.menuTableOfContentsToggle();
                    "
                    >{{ lot.fullName }}</a
                  >
                </div>
              </div>
            </div>
            <div v-if="this.menuColor.show" class="card shadow p-3" style="position: absolute; top: 10px">
              <div class="card-body p-1">
                <button
                  :class="{
                    'is-active': editor.isActive('textStyle', {
                      color: this.currentUser.org.persoColor1 || '#000000',
                    }),
                  }"
                  :style="`background-color: ${this.currentUser.org.persoColor1 || `#000000`}; height: 20px; width: 20px;`"
                  class="btn btn-icon rounded-circle m-1"
                  @click="
                    this.editor
                      .chain()
                      .focus()
                      .setColor(this.currentUser.org.persoColor1 || '#000000')
                      .run()
                  "
                ></button>
                <button
                  :class="{
                    'is-active': editor.isActive('textStyle', {
                      color: this.currentUser.org.persoColor2 || '#195D5B',
                    }),
                  }"
                  :style="`background-color: ${this.currentUser.org.persoColor2 || `#195D5B`}; height: 20px; width: 20px;`"
                  class="btn btn-icon rounded-circle m-1"
                  @click="
                    this.editor
                      .chain()
                      .focus()
                      .setColor(this.currentUser.org.persoColor2 || '#195D5B')
                      .run()
                  "
                ></button>
                <button
                  :class="{
                    'is-active': editor.isActive('textStyle', {
                      color: this.currentUser.org.persoColor3 || '#3C78D8',
                    }),
                  }"
                  :style="`background-color: ${this.currentUser.org.persoColor3 || `#3C78D8`}; height: 20px; width: 20px;`"
                  class="btn btn-icon rounded-circle m-1"
                  @click="
                    this.editor
                      .chain()
                      .focus()
                      .setColor(this.currentUser.org.persoColor3 || '#3C78D8')
                      .run()
                  "
                ></button>
                <button
                  :class="{
                    'is-active': editor.isActive('textStyle', {
                      color: this.currentUser.org.persoColor4 || '#A64D79',
                    }),
                  }"
                  :style="`background-color: ${this.currentUser.org.persoColor4 || `#A64D79`}; height: 20px; width: 20px;`"
                  class="btn btn-icon rounded-circle m-1"
                  @click="
                    this.editor
                      .chain()
                      .focus()
                      .setColor(this.currentUser.org.persoColor4 || '#A64D79')
                      .run()
                  "
                ></button>
                <button
                  :class="{
                    'is-active': editor.isActive('textStyle', {
                      color: this.currentUser.org.persoColor5 || '#CC0000',
                    }),
                  }"
                  :style="`background-color: ${this.currentUser.org.persoColor5 || `#CC0000`}; height: 20px; width: 20px;`"
                  class="btn btn-icon rounded-circle m-1"
                  @click="
                    this.editor
                      .chain()
                      .focus()
                      .setColor(this.currentUser.org.persoColor5 || '#CC0000')
                      .run()
                  "
                ></button>
                <button
                  :class="{
                    'is-active': editor.isActive('textStyle', {
                      color: this.currentUser.org.persoColor6 || '#F1C232',
                    }),
                  }"
                  :style="`background-color: ${this.currentUser.org.persoColor6 || `#F1C232`}; height: 20px; width: 20px;`"
                  class="btn btn-icon rounded-circle m-1"
                  @click="
                    this.editor
                      .chain()
                      .focus()
                      .setColor(this.currentUser.org.persoColor6 || '#F1C232')
                      .run()
                  "
                ></button>
                <i
                  class="bi bi-x text-gray-800 text-hover-primary cursor-pointer align-middle fs-1"
                  @click="this.menuColorToggle()"
                ></i>
              </div>
            </div>
            <div
              v-if="this.modalVersion.show"
              class="card shadow w-350px p-3"
              style="position: absolute; top: 10px; right: 10px"
            >
              <i
                class="bi bi-x text-gray-800 text-hover-primary cursor-pointer fs-1"
                style="position: absolute; top: 10px; right: 10px"
                @click="this.modalVersionCancel()"
              ></i>
              <div class="card-body overflow-x-scroll">
                <div v-if="this.versions.length === 0" class="text-center">
                  <p class="text-muted">Aucune version enregistrée sur ce document</p>
                </div>
                <div
                  v-for="version in this.versions"
                  v-else
                  :key="version.id"
                  class="text-nowrap text-truncate overflow-y-hidden overflow-x-scroll"
                >
                  <p class="mb-0 pb-0">
                    <!--                    <button class="btn btn-icon btn-sm btn-outline border-0" disabled>
                      <span class="bi bi-arrow-counterclockwise"></span>
                    </button>-->
                    Version {{ version.number }}
                    <span v-if="version.createdAt"> ({{ new Date(version.createdAt).toLocaleString("fr-FR") }})</span>
                  </p>
                </div>
                <div class="text-center">
                  <button
                    :disabled="this.$apollo.queries.versions.loading"
                    class="btn btn-primary text-center mt-3"
                    @click="this.modalVersionCreate()"
                  >
                    Figer une version<span v-if="this.$apollo.queries.versions.loading" class="spinner-border"></span>
                  </button>
                  <p class="text-muted text-center mt-2">
                    Les versions figées vous seront utiles pour le dossier de consultation des entreprises
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div v-if="editor" class="form__item form__item--title">
            <PrintHeader
              v-if="this.editor && this.currentFocus"
              :disabled="!this.localDescro.documentName"
              :pageTitle="this.localDescro.documentName"
              sectionTitle=""
            ></PrintHeader>
            <div v-if="this.editor && this.currentFocus"></div>
            <div class="p-10 print-no-padding overflow-visible">
              <editor-content :key="this.floatingMenuKey" :editor="this.editor" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- MODALS -->
  <div>
    <KalmModal v-if="this.modals.restoreVersion.show" modal-title="Restaurer la version">
      <template v-slot:body>
        <p class="text-muted">
          En restaurant cette version du descriptif, vous remplacerez l'actuelle version par cette version :
        </p>
        <p class="fw-bold mb-0">{{ version.name }}</p>
        <p class="">Version {{ version.number }} ({{ new Date(this.version.createdAt).toLocaleString("fr-FR") }})</p>
        <p class="text-center">
          Une fois la version restaurée, actualisez le descriptif pour charger le document dans la bonne version.
        </p>
      </template>
      <template v-slot:footer>
        <div class="d-flex justify-content-end">
          <button class="btn btn-secondary me-2" @click="this.modals.restoreVersion.show = false">Annuler</button>
          <button class="btn btn-primary" @click="this.restoreVersionValidate()">Restaurer</button>
        </div>
      </template>
    </KalmModal>
  </div>
</template>

<script>
import kalmStore from "@/store";

import PrintHeader from "@/views/project/tender/PrintHeader";
import { Color } from "@tiptap/extension-color";
import Link from "@tiptap/extension-link";
import TaskItem from "@tiptap/extension-task-item";
import TaskList from "@tiptap/extension-task-list";
import TextStyle from "@tiptap/extension-text-style";
import { Underline } from "@tiptap/extension-underline";
import StarterKit from "@tiptap/starter-kit";
import { Editor, EditorContent } from "@tiptap/vue-3";

import TableOfContentsNode from "@/views/project/description/CustomNodes/TableOfContents/tableOfContentsNode";
import PageBreakNode from "@/views/project/description/CustomNodes/PageBreak/pageBreakNode";
import CustomImage from "@/views/project/description/CustomNodes/CustomImage/customImage";
import { customDocument, docLot } from "./DocContentNodes";
import { lotContent, lotGroup, lotHeading, lotOuvrage, lotParagraph, lotText } from "./LotContentNodes";

import {
  ALL_BIBLIO_QUERY,
  ALL_PROJECTS_SIMPLE_QUERY,
  CURRENT_USER_QUERY,
  GET_PROJECT_BY_ID,
  GET_VERSIONS_FOR_PROJECT,
  GET_VERSION_BY_ID,
  RESTORE_VERSION_MUTATION,
} from "@/graphql/graphql";
import { Paragraph } from "@tiptap/extension-paragraph";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

import { EXPORT_DOCUMENT_EXCEL_MUTATION } from "@/views/project/description/data/documents_graphql";
import KalmModal from "@/views/_core/components/KalmModal.vue";

export default {
  name: "ProjectViewVersionPage",
  components: {
    KalmModal,
    EditorContent,
    PrintHeader,
  },
  apollo: {
    version: {
      query: GET_VERSION_BY_ID,
      variables() {
        return {
          id: this.$route.params.versionId || this.$route.query.versionId,
        };
      },
      skip() {
        return !this.$route.params.versionId && !this.$route.query.versionId;
      },
      result({ data }) {
        if (data?.version) {
          this.localDescro.document = JSON.parse(data.version.document).doc;
        }
      },
    },
    versions: {
      query: GET_VERSIONS_FOR_PROJECT,
      variables() {
        return {
          projectId: this.currentFocus,
        };
      },
      skip() {
        return !this.currentFocus;
      },
    },
    project: {
      query: GET_PROJECT_BY_ID,
      variables() {
        return {
          id: this.currentFocus,
        };
      },
    },
    currentUser: {
      query: CURRENT_USER_QUERY,
    },
    biblios: {
      query: ALL_BIBLIO_QUERY,
    },
    projectsSimple: {
      query: ALL_PROJECTS_SIMPLE_QUERY,
      update: (data) => data.projects,
    },
    projectToImport: {
      query: GET_PROJECT_BY_ID,
      variables() {
        return {
          id: this.modals.import.projectId,
        };
      },
      skip() {
        return !this.modals?.import?.projectId;
      },
      update: (data) => data.project,
    },
  },
  data() {
    return {
      version: {
        document: null,
      },
      project: null,
      fileHeader: "",
      fileFooter: "",
      biblios: [],
      projectToImport: null,
      projectsSimple: [],
      currentUser: {},
      menuTableOfContents: {
        show: false,
      },
      menuColor: {
        show: false,
      },
      menuTypeStyle: "paragraph",
      editor: null,
      isEditorScrolledDown: false,
      localDescro: {
        id: "",
        document: {},
        documentName: null,
        lastDocUpdate: null,
      },
      leaveGuard: false,
      isSaving: false,
      versions: [],
      modalVersion: {
        show: false,
      },
      modals: {
        restoreVersion: {
          show: false,
        },
        print: {
          show: false,
          lots: [],
          exportFormat: "PDF_FULL",
          exportUnit: false,
          exportUnitPrice: false,
          exportQt: false,
          forceBreakPageLot: true,
          marginTop: "18mm",
          marginBottom: "15mm",
          marginLeft: "18mm",
          marginRight: "18mm",
          loading: false,
        },
        forceSave: {
          show: false,
        },
        import: {
          page: 0,
          show: false,
          searchString: "",
          biblioId: null,
          projectId: "",
          lotId: "",
          ouvrages: [],
          selectedOuvrages: [],
          includeDefault: false,
        },
        export: {
          page: 0,
          show: false,
        },
        importFromExcel: {
          show: false,
          page: 0,
          clipboardData: "",
          fileData: null,
          mode: "clipboard",
          columnMap: {},
          possibleColumns: [
            { label: "Titre", id: "title", required: true },
            { label: "Description", id: "description" },
            /*{ label: 'Lot', id: 'lot'},*/
            /*{ label: 'Emplacement(s)', id: 'locations'},*/
            { label: "Prix unitaire", id: "unitPrice" },
            { label: "Quantité", id: "qt" },
            { label: "Unité", id: "unit" },
          ],
          importColumns: [],
        },
      },
      floatingMenuKey: 0,
    };
  },
  beforeRouteLeave(to, from, next) {
    if (this.leaveGuard) {
      if (window.confirm("Vous avez des changements non sauvegardés. Êtes-vous sûr de vouloir quitter ?")) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },
  methods: {
    restoreVersionValidate() {
      this.$apollo
        .mutate({
          mutation: RESTORE_VERSION_MUTATION,
          variables: {
            projectId: this.currentFocus,
            versionId: this.version.id,
          },
          refetchQueries: [
            {
              query: GET_PROJECT_BY_ID,
              variables: {
                id: this.currentFocus,
              },
            },
          ],
        })
        .then(() => {
          this.$router.push({ name: "project.description" });
          toast.success("Version restaurée avec succès - actualisez le descriptif.");
          this.modals.restoreVersion.show = false;
        })
        .catch((error) => {
          console.error("Erreur lors de la restauration de la version:", error);
          toast.error("Erreur lors de la restauration de la version");
        });
    },
    scrollToLot(hash = null) {
      console.log("----scroll to lot----");
      if (hash) {
        const element = document.querySelector(hash);
        if (element) {
          window.scrollTo({
            top: element.offsetTop + 150,
            behavior: "smooth",
          });
        } else {
          console.log("element DOES NOT EXIST");
        }
      }
      console.log("----END scroll lot----");
    },
    getCommonVariables() {
      return {
        projectId: kalmStore.getters.currentProjectFocus,
        type: "DESCRIPTION",
      };
    },
    fetchFocusDoc() {
      console.log("[FocusDoc]");
      const doc = this.localDescro.document;
      let newDoc = null;

      if (doc && doc?.type === "docType") {
        newDoc = doc;
        console.log("[FocusDoc] newDoc", newDoc);
      }

      return newDoc;
    },
    printDocument() {
      this.$nextTick(function () {
        window.print();
      });
    },
    modalPrintShow() {
      this.modals.print.show = true;
      if (this.modals.print.lots?.length === 0) {
        this.modals.print.lots = this.currentProject.lots.map((l) => l.id);
      }
    },
    modalPrintClose() {
      this.modals.print.show = false;
    },
    modalPrintValidate() {
      switch (this.modals.print.exportFormat) {
        case "PDF":
          this.modals.print.show = false;
          this.editor.commands.showLotsInPrint(
            this.lots.map((l) => l.id),
            this.modals.print.lots
          );
          this.printDocument();
          break;
        case "EXCEL":
        case "WORD":
          this.modals.print.loading = true;
          this.$apollo
            .mutate({
              mutation: EXPORT_DOCUMENT_EXCEL_MUTATION,
              variables: {
                projectId: this.currentFocus,
                exportFormat: this.modals.print.exportFormat,
                lots: this.modals.print.lots,
                includeUnit: this.modals.print.exportUnit,
                includeUnitPrice: this.modals.print.exportUnitPrice,
                includeQt: this.modals.print.exportQt,
              },
            })
            .then((result) => {
              if (result?.data?.projectExportDocument?.url) {
                const url =
                  (result?.data?.projectExportDocument.url.startsWith("http") ? "" : process.env.VUE_APP_URL_BACK) +
                  result.data.projectExportDocument.url;
                const filename =
                  (this.project?.name || "") +
                  " - Descriptif." +
                  (this.modals.print.exportFormat === "EXCEL" ? "xlsx" : "docx");

                fetch(url)
                  .then((response) => response.blob())
                  .then((blob) => {
                    const link = document.createElement("a");
                    link.href = URL.createObjectURL(blob);
                    link.download = filename;
                    link.click();
                  })
                  .catch(console.error);
                this.modals.print.loading = false;
                this.modals.print.show = false;
              }
            })
            .catch((reason) => {
              console.log("[error] ", reason.message);
              toast.error("Erreur lors de l'export");
              this.modals.print.loading = false;
            });
          break;
        case "PDF_FULL":
          this.modals.print.loading = true;
          this.$apollo
            .mutate({
              mutation: EXPORT_DOCUMENT_EXCEL_MUTATION,
              variables: {
                projectId: this.currentFocus,
                exportFormat: "PDF_FULL",
                lots: this.modals.print.lots,
                fileHeader: this.fileHeader,
                fileFooter: this.fileFooter,
                includeUnit: this.modals.print.exportUnit,
                includeUnitPrice: this.modals.print.exportUnitPrice,
                includeQt: this.modals.print.exportQt,
                forceBreakPageLot: this.modals.print.forceBreakPageLot,
                marginTop: this.modals.print.marginTop,
                marginBottom: this.modals.print.marginBottom,
                marginLeft: this.modals.print.marginLeft,
                marginRight: this.modals.print.marginRight,
              },
            })
            .then((result) => {
              if (result?.data?.projectExportDocument?.url) {
                const url =
                  (result?.data?.projectExportDocument.url.startsWith("http") ? "" : process.env.VUE_APP_URL_BACK) +
                  result.data.projectExportDocument.url;
                const filename = (this.project?.name || "") + " - Descriptif.pdf";

                fetch(url)
                  .then((response) => response.blob())
                  .then((blob) => {
                    const link = document.createElement("a");
                    link.href = URL.createObjectURL(blob);
                    link.download = filename;
                    link.click();
                  })
                  .catch(console.error);
                this.modals.print.loading = false;
                this.modals.print.show = false;
              }
            })
            .catch((reason) => {
              console.log("[error] ", reason.message);
              toast.error("Erreur lors de l'export");
              this.modals.print.loading = false;
            });
          break;
        default:
          console.log("aucune action prévue lorsque exportFormat =", this.modals.print.exportFormat);
          return;
      }
    },
    modalExportShow() {},
    modalExportCancel() {},
    modalExportValidate() {},
    modalForceSaveShow() {
      this.modals.forceSave.show = true;
    },
    modalForceSaveCancel() {
      this.modals.forceSave.show = false;
    },
    modalForceSaveValidate() {
      this.saveDocument(true);
      this.modalForceSaveCancel();
    },
    modalImportQuickSelect(selectedOuvrages, mode) {
      if (mode === "all") {
        selectedOuvrages.length = 0;
        this.modals.import.ouvrages.forEach((l) => {
          selectedOuvrages.push(l);
        });
      } else {
        selectedOuvrages.length = 0;
      }
    },
    modalImportFromExcelShow() {
      this.modals.importFromExcel.show = true;
      this.modals.importFromExcel.mode = "start";
      this.modals.importFromExcel.columnMap = {};
      this.modals.importFromExcel.clipboardData = "";
      this.modals.importFromExcel.importColumns = null;
      this.modals.importFromExcel.page = 0;
    },
    modalImportFromExcelClose() {
      this.modals.importFromExcel.show = false;
      this.modals.importFromExcel.mode = "start";
      this.modals.importFromExcel.page = 0;
    },
    modalImportFromExcelValidate() {
      const m = this.modals.importFromExcel.columnMap;

      if (this.modals.importFromExcel.mode === "file") {
        this.modals.importFromExcel.fileData.forEach((l) => {
          // TODO Add try/catch and toast error

          this.editor.commands.insertContent({
            type: "lotOuvrage",
            attrs: {
              locations: l[m["locations"]] || [],
              qt: l[m["qt"]],
              unit: (l[m["unit"]] || "").toLowerCase(),
              unitPrice: l[m["unitPrice"]],
            },
            content: [
              {
                type: "heading",
                attrs: {
                  level: 4,
                },
                content: [
                  {
                    type: "text",
                    text: l[m["title"]],
                  },
                ],
              },
              ...(l[m["description"]] || "").split("\n").map((p) => {
                if (p !== "") {
                  return {
                    type: "paragraph",
                    content: [
                      {
                        type: "text",
                        text: p,
                      },
                    ],
                  };
                } else {
                  return {
                    type: "paragraph",
                  };
                }
              }),
            ],
          });
        });

        toast.success(
          "" +
            this.modals.importFromExcel.fileData.length +
            (this.modals.importFromExcel.fileData.length > 1 ? " ouvrages importés" : " ouvrage importé")
        );
      } else if (this.modals.importFromExcel.mode === "clipboard") {
        // do stuff
      } else {
        // error
      }

      this.modals.importFromExcel.show = false;
    },
    loadEditor() {
      if (this.version.document) {
        this.floatingMenuKey++;
        this.editor = new Editor({
          extensions: [
            customDocument,
            TableOfContentsNode,
            PageBreakNode,
            docLot,
            lotHeading,
            lotContent,
            lotParagraph,
            lotOuvrage,
            lotText,
            lotGroup,
            Underline,
            TextStyle,
            Color,
            TaskItem,
            TaskList,
            Link,
            StarterKit.configure({
              document: false,
              dropcursor: false,
              paragraph: false,
            }),
            Paragraph.extend({
              priority: 1001,
            }),
            CustomImage.configure({
              inline: false,
            }),
          ],
          content: this.localDescro.document,
        });
      }
    },
  },
  mounted() {},
  watch: {
    version: {
      deep: true,
      handler(newValue) {
        if (newValue?.document) {
          this.localDescro.document = JSON.parse(newValue.document).doc;
          console.log("version", newValue.document.doc);
          this.loadEditor();
        }
      },
    },
  },
  computed: {
    ifStoreReady() {
      return this.version.document.hasAttribute("doc");
    },
    isDocumentEmpty() {
      return this?.version && this.version?.document && this.version.document === '"{}"';
    },
    hash() {
      return this.$route.hash || "";
    },
    isDescriptionDisplayModeCollapsed: {
      get() {
        return kalmStore.state.display.descriptionMode === "collapsed";
      },
      set(newValue) {
        const mode = newValue ? "collapsed" : "full";
        kalmStore.commit("updateDescriptionDisplayMode", mode);
      },
    },
    computedColorForTitle() {
      return this.currentUser?.org?.persoColorMain || "#195D5C";
    },
    computedFontForTitle() {
      return this?.currentUser?.org?.persoFontBody || "DM Serif";
    },
    isModalActive() {
      return (
        kalmStore.state.display.profileToggled ||
        kalmStore.state.display.blur ||
        Object.values(this.modals).some((m) => m.show)
      );
    },
    currentFocus() {
      console.log("currentFocus");
      return kalmStore.getters.currentProjectFocus;
    },
    currentProject() {
      return this.project;
    },
    lots() {
      return this.currentProject?.lots;
    },
    getProjects() {
      return this.projectsSimple;
    },
    projectHasFeatureMultiLocation: () => {
      return this.project.featureMultiLocation;
    },
    budgetModule() {
      return true; //always true in read only version mode
    },
    printValueLot: {
      get() {
        return this.$store.getters.printValueLot;
      },
      set() {
        this.$store.commit("togglePrintValueLot");
      },
    },
    printValueQt: {
      get() {
        return this.$store.getters.printValueQt;
      },
      set() {
        this.$store.commit("togglePrintValueQt");
      },
    },
    printValueUnitPrice: {
      get() {
        return this.$store.getters.printValueUnitPrice;
      },
      set() {
        this.$store.commit("togglePrintValueUnitPrice");
      },
    },
  },
  beforeUnmount() {
    if (this.editor) {
      this?.editor.destroy();
    }
    window.removeEventListener("keydown", this.handleKeydown);
    window.removeEventListener("beforeunload", this.beforeWindowUnload);
  },
};
</script>

<style lang="scss">
@media print {
  .print-no-padding {
    padding: 0 !important;
  }
}

.import-ouvrage-shadow {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

/* Basic editor styles */
.tiptap {
  p {
    min-width: 1px;
  }

  img {
    max-width: 80%;
    height: auto;
    margin-top: 5px;
    margin-bottom: 15px;
    border-radius: 7px;
    box-shadow: rgba(99, 99, 99, 0.2) 0 2px 8px 0;

    &.ProseMirror-selectednode {
      outline: 3px solid #68cef8;
    }
  }

  h1 {
    font-size: 22px !important;
    break-after: avoid !important;
    margin-left: 15px !important;
    color: v-bind(computedColorForTitle);
  }

  h2 {
    font-size: 22px !important;
    break-after: avoid !important;
    margin-left: 30px !important;
    color: v-bind(computedColorForTitle);
  }
}
</style>

<style>
.tooltip {
  z-index: 10000;
}

/* Removes the arrows from number inputs*/
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.ProseMirror h4.is-empty::before {
  color: #adb5bd;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}

.ProseMirror h4 + p.is-empty::before {
  color: #adb5bd;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}

.ProseMirror #lotText-node-content > p.is-empty:first-child::before {
  color: #adb5bd;
  content: "Bloc texte : ajoutez des généralités, un titre...";
  float: left;
  height: 0;
  pointer-events: none;
}

@media print {
  .tooltip {
    display: none !important;
    visibility: hidden !important;
  }
}
</style>

<style scoped>
.editor {
  border-radius: 20px;
  margin-top: 20px;
  padding: 0;
  box-shadow: rgba(0, 0, 0, 0.3) 0 20px 30px; /*0 0px 40px 5px rgba(0, 0, 0, 0.2);*/
  background-color: white;
}

.editor-buttons-sticky {
  /*noinspection CssInvalidPropertyValue*/
  position: -webkit-sticky;
  position: sticky;
  top: 70px;
  z-index: 180;
}

.drag-handle {
  flex: 0 0 auto;
  position: relative;
  width: 1rem;
  height: 1rem;
  top: 0.3rem;
  right: 50px;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  margin-top: 0.5rem;
  cursor: grab;
  background-image: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 16"><path fill-opacity="0.2" d="M4 14c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2zM2 6C.9 6 0 6.9 0 8s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0-6C.9 0 0 .9 0 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm6 4c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" /></svg>');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.ProseMirror-focused:focus {
  outline: none !important;
}

.ProseMirror:focus {
  outline: none !important;
}

.ProseMirror-focused:focus-visible {
  outline: none !important;
}

.ProseMirror:focus-visible {
  outline: none !important;
}

.floating-menu {
  display: flex;
  padding: 0.2rem;
  border-radius: 0.5rem;
  z-index: 100 !important;
}

.floating-menu button {
  position: relative;
  color: #000;
  margin: 0.1rem;
  border: 1px solid black;
  border-radius: 0.3rem;
  padding: 0.1rem 0.4rem;
  background: white;
  accent-color: black;
  z-index: 100 !important;
}

.floating-menu button:hover,
.floating-menu button.is-active {
  opacity: 1;
}

.editor-toolbar {
  background-color: #195d5b;
  opacity: 0.95;
  backdrop-filter: blur(10px);
}

.editor-toolbar.toolbar-top {
  border-radius: 20px 20px 0 0;
  min-height: 50px;
}

.editor-toolbar.toolbar-sticky {
  border-radius: 25px;
  min-height: 40px;
}

@media print {
  .card {
    box-shadow: none;
  }

  .print-hidden {
    display: none !important;
  }

  .editor {
    box-shadow: none;
  }
}
</style>
