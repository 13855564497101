<template>
  <KalmModal modalTitle="Template" @close="close">
    <template v-slot:body>
      <label class="form-label" for="content">Nom</label>
      <input id="name" v-model="title" class="form-control" type="text" />
      <div class="float-end mt-2 pt-1">
        <button v-if="!isSaving" :disabled="!canSave()" class="btn btn-primary btn-sm" type="button" @click="save">
          Ajouter
        </button>

        <LoadingButton v-if="isSaving"></LoadingButton>
      </div>
    </template>
  </KalmModal>
</template>

<script>
import { toast } from "vue3-toastify";
import { UPDATE_CANEVA_MUTATION, CREATE_CANEVA_MUTATION } from "../../data/canevas_graphql";
import LoadingButton from "@/views/_core/components/LoadingButton.vue";
import KalmModal from "@/views/_core/components/KalmModal.vue";

export default {
  name: "CanevaFormModal",
  components: {
    LoadingButton,
    KalmModal,
  },
  data() {
    return {
      name: "",
      isSaving: false,
    };
  },
  props: {
    caneva: {
      type: Object,
      Required: false,
    },
  },
  mounted() {
    this.name = this.caneva ? this.caneva.name : "";
  },
  computed: {
    title: {
      get() {
        return this.name;
      },
      set(value) {
        this.name = value;
      },
    },
  },
  methods: {
    canSave() {
      return this.name.length > 0;
    },
    async save() {
      this.isSaving = true;
      if (this.caneva) {
        await this.updateCaneva();
      } else {
        await this.createCaneva();
      }

      this.$emit("close");
    },
    async updateCaneva() {
      try {
        console.log(this.caneva);
        await this.$apollo.mutate({
          mutation: UPDATE_CANEVA_MUTATION,
          variables: {
            canevaId: this.caneva.id,
            name: this.name,
          },
        });
        toast.success("Caneva ajouté avec succès");
      } catch (e) {
        toast.error("Erreur lors de l'ajout du caneva");
      } finally {
        this.isSaving = false;
      }
    },
    async createCaneva() {
      try {
        await this.$apollo.mutate({
          mutation: CREATE_CANEVA_MUTATION,
          variables: {
            name: this.name,
          },
        });
        toast.success("Caneva ajouté avec succès");
      } catch (e) {
        toast.error("Erreur lors de l'ajout du caneva");
      } finally {
        this.isSaving = false;
      }
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped></style>
