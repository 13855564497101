<template>
  <node-view-wrapper as="span" class="d-flex kalm-custom-img" data-drag-handle draggable="true">
    <div class="d-block">
      <!--suppress RequiredAttributes -->
      <img
        :style="'width: ' + node.attrs.width"
        alt="Image non décrite inclue dans le descriptif"
        draggable="true"
        height="auto"
        v-bind="node.attrs"
        @mouseenter="showImageActions = true"
        @mouseleave="showImageActions = false"
      />
    </div>
    <div
      v-if="showImageActions"
      class="print-hidden position-absolute image-resize-actions"
      @mouseenter="showImageActions = true"
      @mouseleave="showImageActions = false"
    >
      <div class="d-flex justify-content-center">
        <div v-if="this.editor.isEditable">
          <div class="btn btn-icon btn-light w-30px h-30px me-1" @click="this.updateAttributes({ width: '25%' })">
            S
          </div>
          <div class="btn btn-icon btn-light w-30px h-30px me-1" @click="this.updateAttributes({ width: '50%' })">
            M
          </div>
          <div class="btn btn-icon btn-light w-30px h-30px me-1" @click="this.updateAttributes({ width: '75%' })">
            L
          </div>
        </div>
        <div
          v-if="this.node.attrs.fileId"
          class="btn btn-icon btn-light w-30px h-30px me-0"
          @click="this.toggleModalAssignTask()"
        >
          <span class="bi-list"></span>
        </div>
      </div>
    </div>
  </node-view-wrapper>
</template>

<script>
import { NodeViewWrapper, nodeViewProps } from "@tiptap/vue-3";

export default {
  components: {
    NodeViewWrapper,
  },

  props: nodeViewProps,

  data() {
    return {
      showImageActions: false,
    };
  },

  methods: {
    toggleModalAssignTask() {
      this.$store.commit("toggleModalAssignTask", {
        fileId: this.node.attrs.fileId,
      });
    },
  },
};
</script>

<style>
/* Basic editor styles */
.tiptap .kalm-custom-img img {
  max-width: 70%;
  height: auto;
  margin-top: 5px;
  margin-bottom: 15px;
  border-radius: 7px;
  box-shadow: rgba(99, 99, 99, 0.2) 0 2px 8px 0;
}

@media screen {
  .tiptap .kalm-custom-img.ProseMirror-selectednode img {
    outline: 3px solid #68cef8;
  }
}
</style>
