<template>
  <div class="col-12 mb-5">
    <div class="d-flex align-items-center justify-content-between my-4">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label fw-bolder text-dark fs-3">{{ caneva.name }}</span>
      </h3>
      <div class="card-toolbar">
        <ul class="nav nav-pills nav-pills-sm nav-light">
          <li class="nav-item">
            <button :disabled="this.readOnly" class="nav-link btn btn-primary fw-bolder me-2" @click="addTodo">
              Ajouter une Todo
            </button>
          </li>
          <li class="nav-item">
            <button class="nav-link btn btn-primary fw-bolder me-2" @click="importCaneva(caneva.id)">
              <span class="bi bi-stars">Importer le template</span>
            </button>
          </li>
        </ul>
      </div>
    </div>
    <div class="card-body">
      <TodosList
        :assignedToCurrentUser="false"
        :canevaId="caneva.id"
        :initialItems="caneva.todos"
        :loading="loading"
        :projectId="null"
        :readOnly="this.readOnly"
        emptyStateType="image"
        @addTodo="addTodo"
      />
    </div>
    <TodoFormModal
      v-if="modals.editTodo.show"
      :assignedToCurrentUser="false"
      :canevaId="caneva.id"
      :filterAssignedTo="[]"
      :filterProject="[]"
      :projectId="null"
      :readOnly="this.readOnly"
      @close="closeTodoEditModal"
    />
  </div>
</template>

<script>
import TodoFormModal from "@/views/todos/presentation/todo-form/TodoFormModal.vue";
import TodosList from "@/views/todos/presentation/todos/components/TodosList.vue";

export default {
  name: "CanevaTodo",
  components: {
    TodoFormModal,
    TodosList,
  },
  props: {
    caneva: {
      type: Object,
      Required: true,
    },
  },
  computed: {
    readOnly() {
      return this.caneva.type !== "PRIVATE";
    },
  },
  data() {
    return {
      loading: false,
      modals: {
        editTodo: {
          show: false,
        },
        caneva: {
          show: false,
        },
      },
    };
  },
  methods: {
    closeTodoEditModal() {
      this.modals.editTodo.show = false;
      this.refresh();
    },
    refresh() {
      this.$emit("refresh");
    },
    importCaneva(canevaId) {
      this.$emit("import-caneva", canevaId);
    },
    addTodo() {
      this.modals.editTodo.show = true;
    },
  },
};
</script>
