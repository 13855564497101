<template>
  <div>
    <div class="">
      <label class=""
        >Disposition des fichiers
        <span v-tooltip:bottom="'Nombre de fichiers par ligne'" class="ms-1 bi bi-info-circle"></span>
      </label>
      <div>
        <input id="layoutCheckbox1" v-model="this.layout" :value="1" class="btn-check" type="radio" />
        <label class="btn btn-sm rounded-end-0 btn-light" for="layoutCheckbox1" title="Largeur complète">
          1 <span class="ms-2 bi bi-square"></span>
        </label>
        <input id="layoutCheckbox2" v-model="this.layout" :value="2" class="btn-check" type="radio" />
        <label class="btn btn-sm rounded-0 btn-light" for="layoutCheckbox2" title="2 par ligne">
          2 <span class="ms-2 bi bi-grid"></span>
        </label>
        <input id="layoutCheckbox3" v-model="this.layout" :value="3" class="btn-check" type="radio" />
        <label class="btn btn-sm rounded-start-0 btn-light" for="layoutCheckbox3" title="3 par ligne">
          3 <span class="ms-2 bi bi-grid-3x2-gap"></span>
        </label>
      </div>
    </div>
    <div class="mt-4 mb-10">
      <label>Ajouter un fichier :</label>
      <input ref="fileForTaskFileInput" class="form-control" multiple type="file" @change="handleFileChange" />
    </div>
    <div v-if="task.files.length > 0">
      <p class="fw-bold text-gray-700">Fichiers liées à la tâche :</p>
      <FilesForTask :taskId="taskId" />
    </div>
    <div v-else class="">
      <p>Aucun fichier liée à cette tâche</p>
    </div>
  </div>
</template>

<script>
import { FILE_UPLOAD } from "@/views/documents/data/files_graphql";
import { GET_TASK_BY_ID, UPDATE_TASK } from "@/views/tasks/data/tasks_graphql";
import FilesForTask from "./FilesForTask.vue";

export default {
  name: "FilesForTaskForm",
  apollo: {
    task: {
      query: GET_TASK_BY_ID,
      variables() {
        return {
          taskId: this.taskId,
        };
      },
      skip() {
        return !this.taskId;
      },
    },
  },
  data() {
    return {
      task: {
        files: [],
      },
      isLoading: false,
    };
  },
  props: {
    taskId: {
      type: String,
      required: true,
    },
  },
  components: {
    FilesForTask,
  },
  methods: {
    close() {
      this.$emit("close");
    },
    handleFileChange(event) {
      this.isLoading = true;
      const selectedFiles = event.target.files;
      selectedFiles.forEach((file, index) => {
        this.$apollo
          .mutate({
            mutation: FILE_UPLOAD,
            variables: {
              file,
              projectId: this.projectId,
              taskId: this.taskId,
              type: "MONITORING",
            },
            context: {
              hasUpload: true,
            },
            refetchQueries: [
              {
                query: GET_TASK_BY_ID,
                variables: {
                  taskId: this.taskId,
                },
                skip() {
                  return !this.taskId;
                },
              },
            ],
          })
          .then(() => {
            if (index === selectedFiles.length - 1) {
              this.isLoading = false;
              this.$refs.fileForTaskFileInput.value = "";
            }
          });
      });
    },
  },
  computed: {
    projectId() {
      return this.$store.getters.currentProjectFocus;
    },
    layout: {
      get() {
        return this.task.layout;
      },
      set(newValue) {
        console.log("newvalue", newValue);
        this.$apollo.mutate({
          mutation: UPDATE_TASK,
          variables: {
            id: this.taskId,
            input: {
              layout: newValue,
            },
          },
        });
      },
    },
  },
};
</script>
