<template>
  <div class="card card-stretch">
    <div class="card-header border-0 pt-5">
      <div>
        <h3 class="card-title align-items-start flex-column">
          <span class="card-label fw-bolder text-dark fs-3">{{ title }}</span>
        </h3>
      </div>
      <div v-if="mainButtonMessage">
        <button class="btn btn-primary" @click="mainAction()">{{ mainButtonMessage }}</button>
      </div>
    </div>
    <div class="card-body pt-3 mt-n3">
      <template v-if="this.proposals.length > 0">
        <div :id="'table' + title" class="tab-content mt-4">
          <!--begin::Table-->
          <div class="table-responsive">
            <table class="table table-borderless align-middle">
              <thead>
                <tr>
                  <th class="px-2 py-0 w-50px"></th>
                  <th class="px-2 py-0 min-w-300px"></th>
                  <th class="px-2 py-0 w-200px d-none d-md-block"></th>
                  <th class="px-2 py-0 w-300px"></th>
                </tr>
              </thead>
              <draggable
                :component-data="{
                  tag: 'tr',
                  type: 'transition-group',
                  name: !dragging ? 'flip-list' : null,
                }"
                :disabled="!enabled"
                :list="getProposals()"
                item-key="id"
                tag="tbody"
                v-bind="dragOptions"
                @end="onDropped()"
                @start="dragging = true"
              >
                <template #item="{ element }">
                  <tr :key="element.id" class="col-12 col-sm-6 col-xl-4 col-xxl-4" style="min-height: 200px">
                    <ProposalCard
                      :key="element.id"
                      :loading="loading && loadingTable[element.id]"
                      :proposal="element"
                      @clickOption="openModal"
                    />
                  </tr>
                </template>
              </draggable>
            </table>
          </div>
        </div>
      </template>
      <template v-else-if="this.initialLoading">
        <div>
          <div class="d-flex justify-content-center">
            <div class="spinner spinner-border text-primary"></div>
          </div>
        </div>
      </template>
      <div v-else class="text-center w-75 w-sm-50 mx-auto">
        <slot> Empty </slot>
      </div>
    </div>
  </div>
</template>

<script>
import { BULK_UPDATE_PROPOSALS_ORDER } from "@/graphql/graphql";

import { toast } from "vue3-toastify";

import ProposalCard from "./ProposalCard.vue";
import draggable from "vuedraggable";

export default {
  name: "ProposalsTable",
  components: {
    ProposalCard,
    draggable,
  },
  emits: ["clickOption", "mainAction"],
  props: {
    title: {
      type: String,
      required: true,
    },
    mainButtonMessage: {
      type: String,
      required: false,
    },
    proposals: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    loadingTable: {
      type: Object,
      required: true,
    },
    initialLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      enabled: true,
      dragging: false,
      localProposals: [],
      lastStringifiedProposals: "",
    };
  },
  methods: {
    mainAction() {
      this.$emit("mainAction");
    },
    openModal(args) {
      this.$emit("clickOption", { ...args });
    },
    async onDropped() {
      this.dragging = false;

      try {
        await this.$apollo.mutate({
          mutation: BULK_UPDATE_PROPOSALS_ORDER,
          variables: {
            proposals: [
              ...this.localProposals.map((proposal, index) =>
                JSON.stringify({
                  id: proposal.id,
                  order: index,
                })
              ),
            ],
          },
        });
        this.$emit("onUpdateOrder");
      } catch {
        toast.error("Une erreur est survenue lors de la mise à jour de l'ordre des propositions");
      }
    },
    getProposals() {
      const stringifiedProposals = JSON.stringify(
        this.proposals.map((proposal) => {
          // eslint-disable-next-line no-unused-vars
          const { order, updatedAt, ...rest } = proposal;
          return rest;
        })
      );

      if (this.lastStringifiedProposals !== stringifiedProposals) {
        this.localProposals = [...this.proposals];
        this.lastStringifiedProposals = stringifiedProposals;
      }
      return this.localProposals;
    },
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "proposals",
        ghostClass: "ghost",
      };
    },
  },
};
</script>

<style lang="scss" scoped></style>
