<template>
  <div
    v-if="this.updateExists"
    class="d-flex justify-content-center align-items-baseline bg-danger text-white w-100 p-2"
  >
    <p class="text-center align-baseline">✨ Une version plus récente de Kalm est disponible !</p>
    <button class="btn btn-sm btn-light btn-active-accent ms-4" @click="this.refreshApp()">Actualiser la page</button>
  </div>
  <div class="d-flex flex-column flex-root">
    <div class="page d-flex flex-row flex-column-fluid">
      <MainMenu></MainMenu>
      <div
        id="kt_wrapper"
        :class="{ 'blur-content': expiredSessionModalToggled }"
        class="wrapper print-no-padding d-flex flex-column flex-row-fluid"
      >
        <MainHeader></MainHeader>
        <div class="d-flex flex-column flex-column-fluid">
          <div id="kt_content" class="content fs-6 d-flex flex-column-fluid">
            <div class="container-fluid mx-0">
              <router-view></router-view>
            </div>
          </div>
        </div>
      </div>
      <template v-if="expiredSessionModalToggled">
        <div
          :class="{
            show: expiredSessionModalToggled,
            'd-block': expiredSessionModalToggled,
          }"
          class="modal print-hidden"
        >
          <div class="modal-dialog modal-dialog-centered mw-600px">
            <div class="modal-content mx-5">
              <div class="container px-10 py-10">
                <div class="modal-header p-0 d-flex justify-content-end border-0">
                  <div
                    class="btn btn-icon btn-sm btn-light-primary"
                    @click="
                      this.$store.commit('toggleExpiredSessionModal', {
                        mode: 'close',
                      })
                    "
                  >
                    <i class="bi bi-x-lg"></i>
                  </div>
                </div>

                <div class="modal-body p-0">
                  <div class="d-flex flex-row-fluid justify-content-center">
                    <div class="pb-5 w-100 w-sm-400px fv-plugins-bootstrap5 fv-plugins-framework">
                      <div class="pb-5 completed">
                        <div class="w-100">
                          <div class="pb-5">
                            <h3 class="fw-bolder text-dark display-6">Votre session vient d'expirer</h3>
                            <p class="">
                              Cliquez sur "Me reconnecter", entrez vos identifiants puis revenez sur cette page.
                            </p>
                          </div>
                        </div>
                      </div>

                      <div class="d-flex justify-content-end">
                        <div>
                          <button class="btn btn-lg btn-light" @click="this.toggleExpired('toggleLoginWindow')">
                            1 - Me reconnecter
                          </button>
                          <button class="btn btn-lg btn-light ms-2" @click="this.toggleExpired('close')">
                            2 - C'est bon
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import MainMenu from "@/views/_core/MainMenu";
import MainHeader from "@/views/_core/MainHeader";

export default {
  name: "App",
  data() {
    return {
      refreshing: false,
      updateExists: false,
    };
  },
  components: {
    MainHeader,
    MainMenu,
  },
  computed: {
    expiredSessionModalToggled() {
      return this.$store.state.display.expiredSessionModalToggled;
    },
  },
  methods: {
    toggleExpired(mode) {
      this.$store.commit("toggleExpiredSessionModal", {
        mode,
      });
    },
    refreshApp() {
      console.log("[RELOAD] refresh app button clicked");
      window.location.reload();
    },
  },
};
</script>

<style scoped>
@media print {
  .print-no-padding {
    padding-left: 0 !important;
  }

  @page {
    size: auto;
  }
}
</style>

<style lang="scss">
@use "@/sass/style.scss";

.blur-content {
  filter: blur(5px);
}

* {
  -webkit-print-color-adjust: exact !important; /* Chrome, Safari 6 – 15.3, Edge */
  color-adjust: exact !important; /* Firefox 48 – 96 */
  print-color-adjust: exact !important; /* Firefox 97+, Safari 15.4+ */
}
</style>
