<template>
  <div v-if="this.banner.show">
    <div class="col-12">
      <!-- simple alert -->
      <div
        class="mt-3 alert print-hidden bg-light-primary border border-dashed border-primary d-flex flex-column flex-sm-row"
      >
        <div class="">
          <span :class="'bi-' + this.banner.icon || 'lightbulb'" class="bi text-primary me-4 fs-1 align-middle"></span>
        </div>
        <div class="d-flex flex-column pe-0 pe-sm-10">
          <h5 class="mb-1">{{ this.banner.title }}</h5>
          <p class="mb-1" style="white-space: pre-wrap">
            {{ this.banner.text }}
          </p>
        </div>
      </div>
    </div>
  </div>
  <!--begin::Row-->
  <div class="row pt-2 g-10">
    <div class="col-12 col-xl-5">
      <TodosPage
        :allProjects="true"
        :assigned-to-current-user="true"
        :default-hide-completed="true"
        :reduced-mode="true"
        :show-filters="false"
      />
    </div>
    <div class="col-12 col-xl-7">
      <!--      <div class="">
            <h3 class="card-title align-items-start flex-column pb-3">
              <span class="card-label fw-bolder text-dark fs-2">Parrainage</span>
            </h3>
            <div class="h-100">
              <div class="card h-auto">
                <div class="card-body">
                  <div class="card-title fw-bolder">✨ Gagnez 1 mois gratuit !</div>
                  <div class="card-text">
                    <p>
                      Nous avons une offre de parrainage très simple → 1 mois offert des deux côtés. Pour en bénéficier,
                      rien de plus simple : obtenez votre lien personnalisé unique en cliquant ci-dessous.
                    </p>
                  </div>
                  <div class="mt-5 d-flex justify-content-end">
                    <button class="btn btn-sm btn-primary" @click="this.showTrustlead()">Ça m'intéresse</button>
                  </div>
                </div>
              </div>
            </div>
          </div>-->
      <!-- PROJECTS -->
      <div class="row">
        <div class="col-12 d-flex">
          <h3 class="card-title align-items-start flex-column pb-3">
            <span class="card-label fw-bolder text-dark fs-2">Projets actifs</span>
          </h3>
        </div>
        <div class="col-12 row h-100">
          <div v-for="project in this.lastNProjects()" :key="project.id" class="col-12 col-sm-6 col-xl-6 col-xxl-4 g-3">
            <div
              class="card cursor-pointer h-100"
              @click="this.$router.push({ name: 'project.home', params: { id: project.id } })"
            >
              <img
                :src="project?.coverPhoto || 'https://app.kalm.ai/static/img/kalm-bg.png'"
                alt="Image de couverture du projet"
                class="card-img-top mh-100px object-fit-cover"
                @click="this.$router.push({ name: 'project.home', params: { id: project.id } })"
              />
              <div class="card-body pt-5 pb-3">
                <div class="">{{ project.name }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--    <div class="col-12 col-xl-12">
      <h2 class="fw-bolder mb-4">Nouveautés sur Kalm</h2>
      <div class="row g-5 h-100">
        <div class="col-12 g-5 h-100">
          <div class="card h-100">
            &lt;!&ndash;begin::Body&ndash;&gt;
            <div class="card-body">
              <div
                id="kt_stats_widget_8_carousel"
                class="carousel carousel-custom slide"
                data-bs-interval="8000"
                data-bs-ride="carousel"
              >
                &lt;!&ndash;begin::Heading&ndash;&gt;
                <div class="d-flex flex-stack flex-wrap">
                  &lt;!&ndash;begin::Label&ndash;&gt;
                  <p class="fs-6 text-muted fw-bold pe-2">
                    Consultez les fonctionnalités à venir sur notre
                    <a
                      href="https://kalmai.notion.site/Kalm-Roadmap-Produit-T4-2024-10ee1e909bce80faa5bce316c5819c94"
                      target="_blank"
                      >roadmap publique sur Notion</a
                    >
                  </p>
                  &lt;!&ndash;end::Label&ndash;&gt;

                  &lt;!&ndash;begin::Carousel Indicators&ndash;&gt;
                  <ol class="p-0 m-0 carousel-indicators carousel-indicators-dots">
                    <li class="ms-1" data-bs-slide-to="0" data-bs-target="#kt_stats_widget_8_carousel"></li>
                    <li
                      aria-current="true"
                      class="ms-1 active"
                      data-bs-slide-to="1"
                      data-bs-target="#kt_stats_widget_8_carousel"
                    ></li>
                    <li class="ms-1" data-bs-slide-to="2" data-bs-target="#kt_stats_widget_8_carousel"></li>
                  </ol>
                  &lt;!&ndash;end::Carousel Indicators&ndash;&gt;
                </div>
                &lt;!&ndash;end::Heading&ndash;&gt;

                &lt;!&ndash;begin::Carousel&ndash;&gt;
                <div class="carousel-inner pt-8">
                  &lt;!&ndash;begin::Item&ndash;&gt;
                  <div class="carousel-item">
                    &lt;!&ndash;begin::Section&ndash;&gt;
                    <div class="d-flex flex-column justify-content-between h-100">
                      &lt;!&ndash;begin::Title&ndash;&gt;
                      <h3 class="fs-3 text-gray-800 fw-bold">
                        🎉 Organisez votre agence avec le premier Template de Todo by Kalm
                      </h3>
                      &lt;!&ndash;end::Title&ndash;&gt;

                      &lt;!&ndash;begin::Text&ndash;&gt;
                      <p class="text-gray-600 pt-3 mb-0">
                        Une checklist pour ne rien oublier sur l'avant-projet, de la première réunion client à l'étude
                        de faisabilité et l'ouverture administrative du dossier.
                      </p>
                      &lt;!&ndash;end::Text&ndash;&gt;
                    </div>
                    &lt;!&ndash;end::Section&ndash;&gt;
                  </div>
                  &lt;!&ndash;end::Item&ndash;&gt;

                  &lt;!&ndash;begin::Item&ndash;&gt;
                  <div class="carousel-item active">
                    &lt;!&ndash;begin::Section&ndash;&gt;
                    <div class="d-flex flex-column justify-content-between h-100">
                      &lt;!&ndash;begin::Title&ndash;&gt;
                      <h3 class="fs-3 text-gray-800 fw-bold">
                        ✨ Ajoutez une photo de couverture à vos projets et un logo sur les contacts
                      </h3>
                      &lt;!&ndash;end::Title&ndash;&gt;

                      &lt;!&ndash;begin::Text&ndash;&gt;
                      <p class="text-gray-600 fw-normal pt-3 mb-0">
                        Pour vous permettre de personnaliser toujours plus votre espace de travail.
                      </p>
                      &lt;!&ndash;end::Text&ndash;&gt;
                    </div>
                    &lt;!&ndash;end::Section&ndash;&gt;
                  </div>
                  &lt;!&ndash;end::Item&ndash;&gt;

                  &lt;!&ndash;begin::Item&ndash;&gt;
                  <div class="carousel-item">
                    &lt;!&ndash;begin::Section&ndash;&gt;
                    <div class="d-flex flex-column justify-content-between h-100">
                      &lt;!&ndash;begin::Title&ndash;&gt;
                      <h3 class="fs-3 text-gray-800 fw-bold">👷 Nouvelle page consultation des entreprise</h3>
                      &lt;!&ndash;end::Title&ndash;&gt;

                      &lt;!&ndash;begin::Text&ndash;&gt;
                      <p class="text-gray-600 pt-3 mb-0">
                        Vous pouvez maintenant réordonner les entreprises, stocker des fichiers (comme des devis) sur
                        chaque entreprise consultée, afficher les groupes d'ouvrages, filtrer sur un lot...
                      </p>
                      &lt;!&ndash;end::Text&ndash;&gt;
                    </div>
                    &lt;!&ndash;end::Section&ndash;&gt;
                  </div>
                  &lt;!&ndash;end::Item&ndash;&gt;
                </div>
                &lt;!&ndash;end::Carousel&ndash;&gt;
              </div>
            </div>
            &lt;!&ndash;end::Body&ndash;&gt;

            &lt;!&ndash;begin::Footer&ndash;&gt;
            <div class="card-footer border-0 d-flex flex-stack pt-0 pb-10">
              &lt;!&ndash;begin::Label&ndash;&gt;
              <span class="text-muted fs-6 fw-semibold pe-2">Une question ? Une idée ?</span>
              &lt;!&ndash;end::Label&ndash;&gt;

              <a
                :href="'https://tally.so/r/nWEr4L?user_email=' + currentUser.email"
                class="btn btn-sm btn-primary fw-bold"
                target="_blank"
                >Je donne mon avis</a
              >
            </div>
            &lt;!&ndash;end::Footer&ndash;&gt;
          </div>
        </div>
      </div>
    </div>-->
  </div>
  <!--end::Row-->
</template>

<script>
import { ALL_PROJECTS_NAMES_QUERY, CURRENT_USER_QUERY } from "@/graphql/graphql";
import TodosPage from "@/views/todos/presentation/todos/TodosPage.vue";

export default {
  name: "DashboardPage",
  components: {
    TodosPage,
  },
  apollo: {
    currentUser: CURRENT_USER_QUERY,
    projects: {
      query: ALL_PROJECTS_NAMES_QUERY,
      fetchPolicy: "network-and-cache",
    },
  },
  data: () => ({
    banner: {
      show: false,
      icon: "lightbulb",
      title: "Titre de la bannière",
      text: "Contenu de la bannière sur plusieurs lignesontenu de la bannière sur plusieurs lignesontenu de la bannière sur plusieurs lignesontenu de la bannière sur plusieurs lignesontenu de la bannière sur plusieurs lignesontenu de la bannière sur plusieurs lignesontenu de la bannière sur plusieurs lignesontenu de la bannière sur plusieurs lignesontenu de la bannière sur plusieurs lignes ontenu de la bannière sur plusieurs lignes ontenu de la bannière sur plusieurs lignes ontenu de la bannière sur plusieurs lignes ontenu de la bannière sur plusieurs lignes\n\n\nontenu de la bannière sur plusieurs lignesontenu de la bannière sur plusieurs lignesontenu de la bannière sur plusieurs lignes",
    },
    currentUser: "unavailable",
    projects: [
      {
        name: "Chargement...",
      },
    ],
  }),
  methods: {
    lastNProjects() {
      return JSON.parse(JSON.stringify(this.projects)).filter((p) => !p.archived);
      /*.sort((a, b) => {
          if (a?.lastUpdateTime == null && b?.lastUpdateTime == null) {
            return a?.name.localeCompare(b?.name);
          } else if (a?.lastUpdateTime == null) {
            return 1;
          } else if (b?.lastUpdateTime == null) {
            return -1;
          } else {
            return a.lastUpdateTime < b.lastUpdateTime ? 1 : -1;
          }
        })*/
    },
  },
  computed: {},
};
</script>

<style scoped>
h1,
h2,
h3,
.card {
  transition: all 50ms ease-in-out;
}

.card.clickable:hover {
  transform: scale(1.01);
  cursor: pointer;
}
</style>
