<template xmlns:title="http://www.w3.org/1999/xhtml">
  <node-view-wrapper class="vue-component ms-3">
    <div
      id="lot-group"
      :style="{ backgroundColor: this.hover ? 'rgba(245,245,245,0.5)' : 'transparent' }"
      class="mt-5 pt-5 pb-2 mb-10 rounded"
      @mouseleave="this.hover = false"
      @mouseover="this.hover = true"
    >
      <div class="print-hidden position-absolute d-flex align-content-center align-items-baseline" style="left: -25px">
        <div
          :style="'margin-top: -2px; visibility: ' + (this.showActionButtons ? 'visible' : 'hidden')"
          class="ouvrage-actions-left ouvrage-action-plus"
          contenteditable="false"
          role="button"
          tabindex="0"
          title="Ajouter une ligne"
          @click="plusAction()"
        >
          <svg style="width: 15px; height: 100%; display: block" viewBox="0 0 16 16">
            <path
              d="M7.977 14.963c.407 0 .747-.324.747-.723V8.72h5.362c.399 0 .74-.34.74-.747a.746.746 0 00-.74-.738H8.724V1.706c0-.398-.34-.722-.747-.722a.732.732 0 00-.739.722v5.529h-5.37a.746.746 0 00-.74.738c0 .407.341.747.74.747h5.37v5.52c0 .399.332.723.739.723z"
            ></path>
          </svg>
        </div>
        <div
          :style="'margin-top: -2px; visibility: ' + (this.showActionButtons ? 'visible' : 'hidden')"
          class="ouvrage-actions-left ouvrage-action-drag-handle align-content-center"
          contenteditable="false"
          data-drag-handle
          data-toggle="dropdown"
          draggable="true"
          role="button"
          style=""
          tabindex="0"
          title="Déplacer l'ouvrage"
          @click="toggleContextMenu()"
        >
          <svg
            style="width: 15px; height: 100%; display: block"
            viewBox="-3 0 16 16"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4 14c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2zM2 6C.9 6 0 6.9 0 8s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0-6C.9 0 0 .9 0 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm6 4c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"
              fill-opacity="0.2"
            />
          </svg>
        </div>
        <div v-if="this.contextMenu.show" class="position-relative" @mouseleave="hideContextMenu()">
          <div class="position-absolute mt-1 card border border-1 border-dark-subtle shadow" style="z-index: 1000">
            <div class="">
              <button
                class="w-100 text-nowrap btn btn-sm btn-outline-danger rounded-bottom-0"
                @click="
                  this.deleteNode();
                  this.hideContextMenu();
                "
              >
                Supprimer
              </button>
              <button
                class="w-100 text-nowrap btn btn-sm btn-outline-primary rounded-0"
                @click="
                  this.duplicateNode();
                  this.hideContextMenu();
                "
              >
                Dupliquer
              </button>
              <button class="w-100 text-nowrap btn btn-sm btn-outline-primary rounded-top-0" disabled>À venir</button>
            </div>
          </div>
        </div>
      </div>
      <div class="ps-5">
        <node-view-content id="text-group-content" class="lot-group-content" />
      </div>
    </div>
  </node-view-wrapper>
</template>

<script>
import { NodeViewContent, NodeViewWrapper } from "@tiptap/vue-3";
import { v4 } from "uuid";

export default {
  name: "LotGroupComponent",
  components: {
    NodeViewWrapper,
    NodeViewContent,
  },
  props: {
    deleteNode: {
      type: Function,
      required: true,
    },
    editor: {
      type: Object,
      required: true,
    },
    node: {
      type: Object,
      required: true,
      default: () => ({
        attrs: {},
      }),
    },
    updateAttributes: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      hover: false,
      contextMenu: {
        show: false,
      },
    };
  },
  computed: {
    editorIsFocused() {
      return this.editor.isFocused;
    },
    showActionButtons() {
      return (this.hover || this.contextMenu.show) && this.editor.isEditable;
    },
  },
  methods: {
    duplicateNode() {
      const end = this.getPos() + this.node.nodeSize;
      const copy = JSON.parse(JSON.stringify(this.node));
      copy.content = copy.content.map((node) => {
        if (node.type === "lotOuvrage") {
          return {
            ...node,
            attrs: {
              ...node.attrs,
              id: v4(),
            },
          };
        } else return node;
      });
      this.editor.chain().insertContentAt(end, copy).focus().run();
    },
    hideContextMenu(timing = 0) {
      setTimeout(() => {
        this.toggleContextMenu(false);
      }, timing);
    },
    toggleContextMenu() {
      this.contextMenu.show = !this.contextMenu.show;
    },
    plusAction() {
      const { editor, getPos, node } = this;
      const end = getPos() + node.nodeSize;

      editor.chain().insertContentAt(end, { type: "lotParagraph", content: [] }).focus().run();
    },
  },
  watch: {},
};
</script>

<style>
#text-group > div > h4 {
  margin-bottom: 15px;
}

.lot-group-content > h4 {
  break-after: avoid;
  break-before: avoid;
}

@media print {
  .no-page-break-after {
    page-break-after: avoid !important;
    break-after: avoid !important;
  }
  .no-page-break-before {
    page-break-before: avoid !important;
    break-before: avoid !important;
  }
  .no-page-break-inside {
    page-break-inside: avoid !important;
    break-inside: avoid !important;
  }
  .force-page-break-before {
    page-break-before: always !important;
  }
}
</style>

<style scoped>
/*noinspection CssRedundantUnit*/
.lot-component-shadow {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.ProseMirror-focused:focus {
  outline: none !important;
}

.ProseMirror:focus {
  outline: none !important;
}

.ProseMirror-focused:focus-visible {
  outline: none !important;
}

.ProseMirror:focus-visible {
  outline: none !important;
}

.ouvrage-action-drag-handle {
  flex: 0 0 auto;
  order: 2;

  width: 20px;
  height: 24px;

  border-radius: 3px;
  pointer-events: auto;

  cursor: grab;
}

.ouvrage-action-drag-handle:hover {
  background: rgba(55, 53, 47, 0.08);
}

.ouvrage-actions-left {
  display: flex;
  gap: 10px;

  align-items: center;
  justify-content: center;

  width: 24px;
  height: 24px;

  border-radius: 3px;
  pointer-events: auto;
}

.ouvrage-action-plus {
  order: 1;
  transition: background 20ms ease-in 0s;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 24px;
  height: 24px;

  fill: rgba(55, 53, 47, 0.35);
  cursor: pointer;
  border-radius: 3px;
  pointer-events: auto;
}

.ouvrage-action-plus:hover {
  background: rgba(55, 53, 47, 0.08);
}

.print-info {
  display: none;
}

@media screen {
  div .digital-margins {
    margin-left: 40px;
    margin-right: 60px;
  }
}

@media print {
  div .digital-margins {
    margin-left: 0px;
    margin-right: 0px;
  }
  .lot-component-shadow {
    box-shadow: none;
  }

  .drag-handle {
    display: none;
  }

  .field-qt {
    border: 0;
  }

  .field-multilocation {
    border: 0;
    background-image: unset;
  }

  div .print-margins {
    break-inside: avoid;
    margin-left: 0 !important;
    padding-left: 5px !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }

  .print-border {
    border-left: 1px solid lightgray;
    padding-left: 10px !important;
    margin-bottom: 20px !important;
    margin-top: 15px !important;
    margin-left: 5px !important;
  }

  .ouvrage-left-actions {
    display: none !important;
    visibility: hidden;
  }

  .print-hidden {
    display: none !important;
  }

  .print-info {
    display: block;
  }
}
</style>
