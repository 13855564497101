import gql from "graphql-tag";

export const MUTATION_PROJECT_CREATE_LOT = gql`
  mutation ($projectId: UUID!, $newLot: LotInput!) {
    projectLotCreate(projectId: $projectId, newLot: $newLot) {
      project {
        id
        document
        lots {
          id
          name
          fullName
          number
          assignedCompany {
            id
            name
            company
          }
          startDate
          endDate
          comment
        }
      }
    }
  }
`;

export const MUTATION_PROJECT_BULK_CREATE_LOT = gql`
  mutation ($projectId: UUID!, $newLots: [LotInput!]!) {
    projectLotBulkCreate(projectId: $projectId, newLots: $newLots) {
      project {
        id
        document
        lots {
          id
          name
          fullName
          number
          assignedCompany {
            id
            name
            company
          }
          startDate
          endDate
          comment
        }
      }
    }
  }
`;

export const MUTATION_PROJECT_DELETE_LOT = gql`
  mutation ($projectId: UUID!, $lotId: UUID!) {
    projectLotDelete(projectId: $projectId, lotId: $lotId) {
      project {
        id
        name
        lots {
          id
          name
          fullName
          number
          assignedCompany {
            id
            name
            company
          }
          startDate
          endDate
          comment
        }
        featureMultiLocation
      }
    }
  }
`;

export const MUTATION_PROJECT_UPDATE_LOT = gql`
  mutation (
    $projectId: UUID!
    $lotId: UUID!
    $name: String
    $number: String
    $assignedCompany: UUID
    $startDate: String
    $endDate: String
    $comment: String
  ) {
    projectLotUpdate(
      projectId: $projectId
      lotId: $lotId
      name: $name
      number: $number
      assignedCompany: $assignedCompany
      startDate: $startDate
      endDate: $endDate
      comment: $comment
    ) {
      project {
        lots {
          id
          name
          fullName
          number
          assignedCompany {
            id
            name
            company
          }
          startDate
          endDate
          comment
        }
      }
    }
  }
`;

export const MUTATION_PROJECT_BULK_UPDATE_LOT = gql`
  mutation ($projectId: UUID!, $lots: [LotInputUpdate!]!) {
    projectLotBulkUpdate(projectId: $projectId, lots: $lots) {
      project {
        lots {
          id
          name
          fullName
          number
          assignedCompany {
            id
            name
            company
          }
          startDate
          endDate
          comment
        }
      }
    }
  }
`;

export const GET_LOTS_NAMES_BY_PROJECT_QUERY = gql`
  query LotsByProjectId($projectId: UUID!) {
    lots: lotsByProjectId(projectId: $projectId) {
      id
      name
      number
    }
  }
`;

export const ALL_LOTS_BY_PROJECT_QUERY = gql`
  query LotsByProjectId($projectId: UUID!) {
    lots: lotsByProjectId(projectId: $projectId) {
      id
      name
      fullName
      number
      assignedCompany {
        id
        name
        company
      }
      startDate
      endDate
      comment
    }
  }
`;
