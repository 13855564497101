import gql from "graphql-tag";

export const GET_PROJECT_SETTINGS = gql`
  query ProjectSettingsByProject($projectId: UUID!) {
    projectSettingsByProject(projectId: $projectId) {
      id
      taskSortOrder
      createdAt
      updatedAt
      includedStatuses {
        id
        name
      }
      showComments
      showFiles
    }
  }
`;

export const UPDATE_PROJECT_SETTINGS = gql`
  mutation ProjectSettingUpdate($projectId: UUID!, $taskSortOrder: [String], $includedStatuses: [UUID], $showComments: Boolean, $showFiles: Boolean) {
    projectSettingUpdate(projectId: $projectId, taskSortOrder: $taskSortOrder, includedStatuses: $includedStatuses, showComments: $showComments, showFiles: $showFiles) {
      projectSetting {
        id
        taskSortOrder
        createdAt
        updatedAt
        includedStatuses {
          id
          name
        }
        showComments
        showFiles
      }
    }
  }
`;
